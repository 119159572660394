<template>
    <div class="EditUsuario">
        <modal name="EditUsuario" height="auto">
            <br />
            <div v-if="usuario != ''" class="container">
                <div class="text-left">
                    <h4>Editando {{ usuario.nome }}</h4>
                    <hr />
                    <form @keyup.enter="salvarUsuario()">
                        <div class="row">
                            <div class="col-md-8">
                                <label>Nome completo</label>
                                <input type="text" v-model="usuario.nome" class="form-control rule" />
                            </div>

                            <div class="col-md-4">
                                <label>CPF</label>
                                <input type="text" v-mask="'###.###.###-##'" v-model="usuario.cpf" class="form-control rule" />
                                <span class="text-danger ruleAviso" v-if="cpfAviso">CPF inválido!</span>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-6">
                                <label>E-mail</label>
                                <input type="email" v-model="usuario.email" class="form-control rule" />
                                <span class="text-danger ruleAviso" v-if="emailAviso">Email inválido!</span>
                            </div>

                            <div class="col-md-6">
                                <label>Senha</label>
                                <input type="password" v-model="senha" class="form-control" />
                            </div>
                        </div>

                        <br />

                        <!-- eslint-disable -->
                        <div class="row">
                            <div class="col-md-12">
                                <label>Órgão</label>
                                <select class="form-control" v-on:change="updateOrgaoText()" v-model="usuario.orgao.id">
                                    <option v-for="orgao in listOrgaos" :value="orgao.id">{{ orgao.nome }}</option>
                                </select>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-12">
                                <label>Grupo</label>
                                <select class="form-control" v-model="usuario.usuario_Grupos[0].grupo.id">
                                    <option v-for="grupo in listGrupos" :value="grupo.id">{{ grupo.nome }}</option>
                                </select>
                            </div>
                        </div>

                        <!-- eslint-enable -->
                        <br />
                        <a @click="salvarUsuario()" href="#" :class="classesButtons" >Salvar</a>
                    </form>
                    <br />
                </div>
            </div>

        </modal>
    </div>
</template>

<script>
    /* eslint-disable */
    //import swal from 'sweetalert';
    import {validate} from "gerador-validador-cpf";
    export default {
        name: 'EditUsuario',
        props: ["usuario"],
        data: () => {
            return ({
                listGrupos: "",
                listOrgaos: "",

                nome: "",
                cpf: "",
                cpfAviso: false,
                email: "",
                emailAviso: false,
                senha: null,
                orgaoId: 1,
                grupoId: 1,
            })
        },
        methods: {
            emailAviso: (v) =>
                /^(([^<>()[]\.,;:\s@']+(.[^<>()\[]\.,;:\s@']+)*)|('.+'))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/
                .test(v) || "O e-mail precisa ser válido.",
            fillSelects: function () {
                api.get("/Grupo/Get").then(result => {
                    this.listGrupos = result.data;
                });

                api.get("/Orgao/Get").then(result => {
                    this.listOrgaos = result.data;
                });
            },
            salvarUsuario: function () {
                var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                api.post("/Usuario/Edit?hash=" + cookie, {
                    id: this.$props.usuario.id,
                    nome: this.$props.usuario.nome,
                    email: this.$props.usuario.email,
                    cpf: this.$props.usuario.cpf,
                    senha: this.senha,
                    orgaoId: this.$props.usuario.orgao.id,
                    grupoId: this.$props.usuario.usuario_Grupos[0].grupo.id
                }).then(result => {
                    this.$swal("Sucesso", result.data, "success").then(response => {
                        this.$modal.hide('EditUsuario');
                    });
                }).catch(error => {
                    if (error.response.status == 400) {
                        this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                    }
                    else this.$swal("Erro", error.response.data, "error");
                });
            },
            updateOrgaoText: function () {
                for (var i = 0; i < this.listOrgaos.length; i++) {
                    if (this.listOrgaos[i].id == this.$props.usuario.orgao.id) {
                        this.$props.usuario.orgao.nome = this.listOrgaos[i].nome;
                    }
                }
            },
            clearFields: function () {
                this.usuario.nome = "";
                this.usuario.email = "";
                this.usuario.cpf = "";
                this.usuario.senha = "";
                this.usuario.orgaoId = 1;
                this.usuario.grupoId = 1;
            }

        },
        mounted() {
            this.fillSelects();
        },
        updated() {

        },
        watch: {
            'usuario.cpf' : function (cpf) {
                if(validate(cpf)) {
                    this.cpfAviso = false;
                }else this.cpfAviso = true;
            },
            'usuario.email' : function (email) {
                if(email.match( /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                    this.emailAviso = false;
                }else this.emailAviso = true;
            }
        },
        computed: {
            classesButtons() {
                if(!this.cpfAviso && !this.emailAviso && this.$props.usuario.nome) {
                    return ['btn', 'btn-info', 'btn-block']
                }else return ['btn', 'btn-info', 'btn-block', 'disabled']
            }
        }
    };
    /* eslint-enable */
</script>

<style scoped>
    .ruleAviso {
        color: red;
        font-size: .7rem;
    }
</style>