<template>
  <main>
    <NavComponent id="NavComponent"></NavComponent>
    <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

    <div id="content" class="container container-novidades">
      <div id="info" class="d-flex flex-row justify-content-center">
        <div
          id="box"
          class="d-flex flex-row justify-content-start align-items-center"
        >
          <div class="boxContent">
            <div class="container text-left">
              <div class="toolbar">
                <h1 id="title" class="px-1 my-auto container-title">
                  Novidades
                </h1>
                <div class="my-auto text-right">
                  <v-btn
                    title="Mais Recente."
                    :id="'desc'"
                    icon
                    :color="order === 'desc' ? '#339933' : '#444'"
                    v-on:click="ordering('d')"
                  >
                    <v-icon>{{ icons.mdiSortDesc }}</v-icon>
                  </v-btn>
                  <v-btn
                    title="Mais Antigo."
                    :id="'asc'"
                    icon
                    :color="order === 'asc' ? '#339933' : '#444'"
                    v-on:click="ordering('a')"
                  >
                    <v-icon>{{ icons.mdiSortAsc }}</v-icon>
                  </v-btn>
                </div>
              </div>
              <div id="ItemDisplay">
                <table
                  class="table table-striped"
                  v-if="['md', 'lg', 'xl'].includes($vuetify.breakpoint.name)"
                >
                  <thead>
                    <tr>
                      <th scope="col" class="col-md-4">Data</th>
                      <th scope="col" class="col-md-8">Descrição</th>
                      <th scope="col" class="col-md-1">Funcionalidade</th>
                      <th scope="col" class="col-md-4">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in desserts" :key="item.title">
                      <td>
                        {{ item.date !== "---" ? translate(item.date) : "" }}
                      </td>
                      <td id="descricao">{{ item.title }}</td>
                      <td>
                        {{ traduzFuncionalidade(item.feature) }}
                      </td>
                      <td class="d-flex flex-row">
                        <a
                          class="text-primary"
                          href="#"
                          @click="editNovidade(item)"
                          ><i class="fas fa-edit"></i
                        ></a>
                        <a
                          class="text-danger"
                          href="#"
                          @click="removeNovidade(item)"
                          ><i class="fas fa-trash"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-striped" v-else>
                  <thead>
                    <tr>
                      <th scope="col" class="col-md-4 text-center">
                        Data
                        <hr class="w-50 mx-auto" />
                        Descrição
                        <hr class="w-50 mx-auto" />
                        Funcionalidade
                        <hr class="w-50 mx-auto" />
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in desserts" :key="item.title">
                      <td class="text-center">
                        {{ item.date !== "---" ? translate(item.date) : "" }}
                        <hr class="w-50 mx-auto" />
                        {{ item.title }}
                        <hr class="w-50 mx-auto" />
                        {{ traduzFuncionalidade(item.feature) }}
                        <hr class="w-50 mx-auto" />
                        <a
                          class="text-primary"
                          href="#"
                          @click="editNovidade(item)"
                          ><i class="fas fa-edit"></i
                        ></a>
                        <a
                          class="text-danger"
                          href="#"
                          @click="removeNovidade(item)"
                          ><i class="fas fa-trash"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="SeeMore">
                  <v-btn
                    v-if="displaySeeMore"
                    id="SeeMore"
                    plain
                    :loading="loading"
                    elevation="0"
                    color="#888"
                    width="300"
                    @click="handleLoadMore"
                    class="mt-3"
                  >
                    Ver mais
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <AddNovidade :parent="this" />

    <EditNovidade :novidade="novidadeEditando" />

    <a @click="AddNovidade()" class="btnFloat">
      <FloatButton id="FloatButton" icon="fas fa-plus"></FloatButton>
    </a>
  </main>
</template>

<script>
import { mdiSortCalendarDescending } from "@mdi/js";
import { mdiSortCalendarAscending } from "@mdi/js";
import AddNovidade from "./components/AddNovidade.vue";

import moment from "moment";
import FloatButton from "../../../components/shared/FloatButton.vue";
import NavComponent from "../../../components/shared/NavComponent.vue";
import HeaderPadrao from "../../../components/shared/HeaderPadrao.vue";
import EditNovidade from "./components/EditNovidade.vue";

export default {
  name: "novidades",
  components: {
    NavComponent,
    HeaderPadrao,
    AddNovidade,
    FloatButton,
    EditNovidade,
  },
  data: () => {
    return {
      icons: {
        mdiSortAsc: mdiSortCalendarAscending,
        mdiSortDesc: mdiSortCalendarDescending,
      },
      loading: false,
      desserts: [],
      displaySeeMore: true,
      descricao: null,
      page: 1,
      order: "desc",
      user: null,
      novidadeEditando: "",
      totalItems: null,
    };
  },
  methods: {
    traduzFuncionalidade: function(valor) {
      return valor === false ? "Não" : "Sim";
    },
    getUser: function() {
      var cookie = this.$cookies.get("COOKIE_LEGISON_TOKEN");
      /* eslint-disable */
      api
        .get("/Usuario/GetUserData?hash=" + cookie)
        .then((response) => {
          this.user = response.data;
        })
        .catch((error) => {
          this.$swal("Oops!", error.response.data, "error");
        });
    },
    getTotalItems: function() {
      api
        .get("/Novidades/GetCount")
        .then((res) => {
          this.totalItems = res.data;
        })
        .catch((err) => {
          this.$swal("error".err.message, "Error");
        });
    },
    resetload: function() {
      this.desserts = [];
      this.getInfo(this.page);
    },
    handleLoadMore: function() {
      if (this.desserts.length >= 10) {
        this.getInfo((this.page += 1));
      }
    },
    ordering: function(value) {
      this.displaySeeMore = true;
      this.page = 1;
      if (value === "d") this.order = "desc";
      else if (value === "a") this.order = "asc";
      this.getInfo(this.page);
    },
    getInfo: async function(page) {
      /* eslint-disable */
      this.loading = true;
      api
        .get("/Novidades/GetList?page=" + page + "&order=" + this.order)
        .then((res) => {
          if (page > 1) {
            const addmore = res.data.map((x) => {
              return {
                id: x.id,
                date: x.data,
                title: x.descricao,
                feature: x.feature,
              };
            });
            this.desserts = this.desserts.concat(addmore);
            if (!(this.desserts.length < this.totalItems)) {
              this.displaySeeMore = false;
            }
          } else {
            this.desserts = res.data.map((x) => {
              return {
                id: x.id,
                date: x.data,
                title: x.descricao,
                feature: x.feature,
              };
            });
          }
          if (this.desserts.length <= 0)
            this.desserts.push({
              date: "---",
              title: "Nenhuma novidade cadastrada!",
            });
          this.loading = false;
        })
        .catch((err) => {
          if (this.desserts.length <= 0)
            this.desserts.push({
              date: "---",
              title: "Nenhuma novidade cadastrada!",
            });
          this.loading = false;
          this.$swal("error", err.message, "Error");
        });
    },
    translate: function(value) {
      let date = new moment(value).format("DD [de] MMMM [de] YYYY");
      let months = {
        January: "Janeiro",
        February: "Fevereiro",
        March: "Março",
        April: "Abril",
        May: "Maio",
        June: "Junho",
        July: "Julho",
        August: "Agosto",
        September: "Setembro",
        October: "Outubro",
        November: "Novembro",
        December: "Dezembro",
      };
      let str = date.split(" ");
      str[2] = months[str[2]];
      return str.join(" ");
    },
    AddNovidade: function() {
      this.$modal.show("AddNovidade");
    },
    editNovidade: function(item) {
      this.novidadeEditando = item;
      this.$modal.show("EditNovidade");
    },
    removeNovidade: function(item) {
      this.$swal({
        title: "Confirme",
        text:
          "Você tem certeza que deseja excluir a novidade " + item.title + "?",
        icon: "info",
        showConfirmButton: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
      }).then((response) => {
        if (response.isConfirmed) {
          api
            .delete("/Novidades/Remove?id=" + item.id)
            .then((result) => {
              this.$swal("Sucesso", result.data, "success");
              this.resetload();
            })
            .catch((error) => {
              this.$swal("Error", error.data, "error");
              this.resetload();
            });
        }
      });
    },
  },
  beforeMount: function() {
    this.getTotalItems();
  },
  mounted: function() {
    this.getUser();
    this.getInfo(this.page);
  },
};
</script>

<style scoped>
.container-title {
  margin: 1.5rem !important;
  position: relative;
  left: 0;
}
.toolbar {
  display: flex;
  justify-content: space-between;
}
.SeeMore {
  display: flex;
  justify-content: center;
}

#descricao {
  word-wrap: break-word !important;
  max-width: 30rem;
  text-align: justify;
}
</style>
