<template>
  <div class="Home">
    <NavComponent id="NavComponent"></NavComponent>

    <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

    <div id="content" class="container">
      <div id="info" class="d-flex flex-row justify-content-center">
        <div
          id="box"
          class="d-flex flex-row justify-content-start align-items-center"
        >
          <div class="boxContent">
            <div class="container text-left">
              <br />
              <h3 class="bold-title">
                <i class="fas fa-user-lock"></i> Solicitações de atualizações de
                atos
              </h3>
              <!-- eslint-disable -->
              <br />

              <div class="row" id="unseen">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Número</th>
                      <th scope="col">Tipo</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="solicitacao in solicitacoes">
                      <td>{{ solicitacao.id }}</td>
                      <td>{{ solicitacao.numeroAto }}</td>
                      <td>{{ solicitacao.tipoAto }}</td>
                      <td>
                        <button
                          class="text-primary"
                          @click="inspecionarSolicitacao(solicitacao)"
                        >
                          <i class="fas fa-eye"></i>
                        </button>
                        <button
                          class="text-legisOn"
                          @click="dialogResolucao(solicitacao)"
                        >
                          <i class="fas fa-check"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <center>
                <v-btn
                  v-if="showVerMais"
                  plain
                  elevation="0"
                  color="#888"
                  width="300"
                  @click="getDados(5)"
                  class="mt-2"
                >
                  Ver mais
                </v-btn>
              </center>
              <!-- eslint-enable -->
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="d-flex flex-row justify-content-center"></div>
    </div>

    <DetalhesSolicitacao :solicitacaoInspecionada="solicitacaoInspecionada" />
  </div>
</template>

<script>
/* eslint-disable */
import NavComponent from "../../components/shared/NavComponent.vue";
import HeaderPadrao from "../../components/shared/HeaderPadrao.vue";
import DetalhesSolicitacao from "./components/DetalhesSolicitacao.vue";

export default {
  name: "Home",
  data: () => {
    return {
      // dialogState: false,
      solicitacoes: [],
      solicitacaoInspecionada: "",
      displaySeeMore: false,
      pagination: 0,
    };
  },
  beforeRouteLeave(to, from, next) {
    localStorage.setItem("prevPage", from.path);
    next();
  },
  components: {
    NavComponent,
    HeaderPadrao,
    DetalhesSolicitacao,
  },
  computed: {
    showVerMais: function() {
      if (this.solicitacoes.length >= 5) {
        this.displaySeeMore = true;
        return true;
      }
    },
  },
  methods: {
    resolver: function(solicitacao) {
      api
        .delete(`/SolicitacaoAto/Remove?solicitacaoAtoId=${solicitacao.id}`)
        .then(() => {
          this.getDados;
          this.$swal.fire(
            "Ato resolvido com sucesso!",
            "O ato foi desativado corretamente.",
            "success"
          );
        })
        .catch((error) => {
          this.$swal.fire(
            "Ops... um erro ocorreu!",
            "Algo deu errado ao resolver a situação do ato",
            "error"
          );
        });
    },
    dialogResolucao: function(solicitacao) {
      this.$swal
        .fire({
          title: "Resolução de solicitação de atualização",
          text: `Realmente deseja resolver a solicitação de atualização do ato ${solicitacao.numeroAto}`,
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonColor: "#0A7015",
          confirmButtonText: "Sim, resolver ato!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.resolver(solicitacao);
          }
        });
    },
    inspecionarSolicitacao: function(solicitacao) {
      this.solicitacaoInspecionada = solicitacao;
      this.$modal.show("DetalhesSolicitacao");
    },
    getDados: function(index) {
      this.pagination += index;
      api
        .get(
          `/SolicitacaoAto/GetPaginatedItems?pageIndex=${this.pagination}&pageSize=5`
        )
        .then((response) => {
          if (index === 0) this.solicitacoes = response.data;
          else {
            this.solicitacoesResultantes = response.data;
            this.solicitacoes = this.solicitacoes.concat(
              this.solicitacoesResultantes
            );
          }
        })
        .catch((err) => {
          this.$swal.fire("Ops... um erro ocorreu!", err, "error");
        });
    },
  },
  mounted() {
    this.getDados(0);
  },
};
</script>

<style>
#unseen {
  padding-bottom: 10px !important;
}
.text-legisOn {
  color: #0a7015;
}
</style>
