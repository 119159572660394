<template>
    <div class="AddIniciativa">
        <modal name="AddIniciativa" height="auto">
            <br />
            <div class="container">
                <div class="text-left">
                    <h4>Adicionando iniciativa</h4>

                    <hr />

                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Descrição<span class="text-danger">*</span></label>
                                <input @keyup.enter="salvar()" type="text" v-model="descricao" class="form-control" />
                            </div>
                        </div>

                        <br />

                        <a @click="salvar()" href="#" class="btn btn-info btn-block">Salvar</a>
                    </form>
                    <br />
                </div>
            </div>

        </modal>
    </div>
</template>

<script>
    /* eslint-disable */
    export default {
        name: 'AddIniciativa',
        props: ["parent"],
        data: () => {
            return ({
                descricao: ""
            })
        },
        methods: {         
            salvar: function () {
                api.post("/Iniciativa/Add" + "?uid=" + this.$props.parent.user.id, {
                    descricao: this.descricao,
                })
                .then(result => {
                    this.$swal("Sucesso", result.data, "success")
                    .then(response => {
                        this.clearFields();
                        this.$props.parent.loadIniciativas();
                        this.$modal.hide('AddIniciativa');
                    });
                })
                .catch(error => {
                    if (error.response.status == 400) {
                        this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                    }
                    else this.$swal("Erro", error.response.data, "error");
                });
            },
            clearFields: function () {
                this.descricao = "";
            }
        },
        mounted() {
        },
        updated() {
        }
    };
    /* eslint-enable */
</script>