<template>
    <div class="AddAtos">
        <NavComponent id="NavComponent"></NavComponent>

        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="boxContent">
                        <!-- eslint-disable -->
                        <div class="container text-left">

                            <br />

                            <h3 class="bold-title"><i class="fas fa-file-signature"></i> Adicionando Constituição</h3>
                            
                            <hr />
                            
                            <div class="row">
                                <div class="col-md-3" style="border-right: 1px solid rgb(226 226 226)">
                                    <i :class="iconeItem1"></i> <span :style="idOpcaoAtual === 0 ? 'color: rgb(6 105 16) !important;' : 'color: black !important;'"> Cadastro de ficha </span>  <br />
                                    <i :class="iconeItem2"></i> <span :style="idOpcaoAtual === 1 ? 'color: rgb(6 105 16) !important;' : 'color: black !important;'"> Envio de conteúdo/arquivos </span>  <br />
                                    <i :class="iconeItem3"></i> <span :style="idOpcaoAtual === 2 ? 'color: rgb(6 105 16) !important;' : 'color: black !important;'"> Envio de conteúdo/arquivos Atualizado </span>  <br />
                                </div>
                                <div class="col-md-9">

                                    <form>
                                        <div class="row" v-if="idOpcaoAtual == 0">
                                            <div class="col-md-6">
                                                <label>Iniciativa <span class="text-danger">*</span></label>
                                                <select class="form-control" v-model="ficha.idIniciativa">
                                                    <option v-for="iniciativa in iniciativas" :value="iniciativa.id">{{ iniciativa.descricao }}</option>
                                                </select>
                                            </div>

                                            <div class="col-md-4">
                                                <label>Situação <span class="text-info">(opcional)</span></label>
                                                <select class="form-control" v-model="ficha.idSituacao">
                                                    <option value="0">Selecione</option>
                                                    <option v-for="situacao in situacoes" :value="situacao.id">{{ situacao.descricao }}</option>
                                                </select>
                                            </div>

                                            <div class="col-md-4">
                                                <label>Data do Ato <span class="text-danger">*</span></label>
                                                <input type="date" v-model="ficha.dataAto" class="form-control" />
                                            </div>

                                            <div class="col-md-4">
                                                <label>Data de Publicação <span class="text-danger">*</span></label>
                                                <input type="date" v-model="ficha.dataPublicacao" class="form-control" />
                                            </div>

                                            <div class="col-md-12 pb-0">
                                                <label>Ementa <span class="text-danger">*</span></label>
                                                <v-textarea auto-grow v-model="ficha.ementa" outlined class="w-100" />
                                            </div>
                                        </div>

                                        <div v-if="idOpcaoAtual == 1">
                                            <div class="container-file-input">
                                                <form>
                                                    <label>Editor de texto (HTML) - <b>Constituição Original</b></label>
                                                    <wysiwyg v-model="content.texto" placeholder="Insira aqui o texto" />
                                                    <br />
                                                    <div class="row justify-content-center">
                                                        <div class="col-6">
                                                            <label> Constituição Original </label>
                                                            <v-file-input
                                                                label="Selecione o arquivo"
                                                                v-model="content.arquivo"
                                                                dense
                                                                outlined
                                                                show-size
                                                                accept="application/pdf" />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div v-if="idOpcaoAtual == 2">
                                            <form>
                                                <label>Editor de texto (HTML) - <b>Ato Atualizado</b> </label>
                                                <wysiwyg v-model="content.textoAtualizado" placeholder="Insira aqui o texto" />
                                                <br />
                                                <div class="row justify-content-center">
                                                    <div class="col-6">
                                                        <label> Constituição Atualizada </label>
                                                        <v-file-input
                                                            label="Selecione o arquivo"
                                                            v-model="content.arquivoAtualizado"
                                                            dense
                                                            outlined
                                                            show-size
                                                            accept="application/pdf" />
                                                        <!--
                                                        <span v-if="pdfAtualcons">
                                                            <b>Arquivo atual:</b> 
                                                            <a :target="'_blank'" :href="caminhoBase + pdfAtualcons">{{ pdfAtualcons }}</a>
                                                        </span>
                                                        -->
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div v-if="idOpcaoAtual == 3">
                                            <h4 class="text-success">Constituição cadastrada com sucesso!</h4>
                                        </div>

                                        <br />

                                        <a @click="salvar()" style="color:#fff !important" v-if="idOpcaoAtual < 3" href="#" class="btn btn-info btn-block">{{ opcaoAtual[idOpcaoAtual].texto }}</a>
                                    </form>
                                </div>
                            </div>
                            <br />
                        </div>
                        <!-- eslint-enable -->
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
            </div>
        </div>
    </div>
</template>

<script>
    import FloatButton from '../../../components/shared/FloatButton.vue';
    import NavComponent from '../../../components/shared/NavComponent.vue';
    import HeaderPadrao from '../../../components/shared/HeaderPadrao.vue';
    import { mdiChevronDown } from '@mdi/js';

    export default {
        /* eslint-disable */
        name: 'AddAtos',
        data: () => {
            return ({
                grupo: [],
                user: null,
                ficha: {
                    idIniciativa: 1,
                    dataAto: null,
                    dataPublicacao: null,
                    ementa: "",
                    idSituacao: 0,
                },
                content: {
                    texto: "<div title=\"header\" style=\"color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;\"><p align=\"center\" style=\"text-align: center; margin-bottom: 0cm; direction: ltr; background: transparent;\"><img src=\"https://i.imgur.com/hQorHaT.png\" name=\"LOGO-GOVERNO-SERGIPE.png\" align=\"bottom\" width=\"189\" height=\"227\" border=\"0\"></p><div title=\"header\">",
                    textoAtualizado: "<div title=\"header\" style=\"color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;\"><p align=\"center\" style=\"text-align: center; margin-bottom: 0cm; direction: ltr; background: transparent;\"><img src=\"https://i.imgur.com/hQorHaT.png\" name=\"LOGO-GOVERNO-SERGIPE.png\" align=\"bottom\" width=\"189\" height=\"227\" border=\"0\"></p><div title=\"header\">",
                    arquivo: null,
                    arquivoAtualizado: null,
                },
                txtcons: "<div title=\"header\" style=\"color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;\"><p align=\"center\" style=\"text-align: center; margin-bottom: 0cm; direction: ltr; background: transparent;\"><img src=\"https://i.imgur.com/hQorHaT.png\" name=\"LOGO-GOVERNO-SERGIPE.png\" align=\"bottom\" width=\"189\" height=\"227\" border=\"0\"></p><div title=\"header\">",
                txtorig: "<div title=\"header\" style=\"color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;\"><p align=\"center\" style=\"text-align: center; margin-bottom: 0cm; direction: ltr; background: transparent;\"><img src=\"https://i.imgur.com/hQorHaT.png\" name=\"LOGO-GOVERNO-SERGIPE.png\" align=\"bottom\" width=\"189\" height=\"227\" border=\"0\"></p><div title=\"header\">",
                idOpcaoAtual: 0,
                opcaoAtual: [{ id: 0, texto: "Confirmar cadastro da ficha" },
                { id: 1, texto: "Confirmar cadastro do conteúdo original" },
                { id: 2, texto: "Confirmar cadastro do conteúdo atualizado" }],
                situacoes: "",
                iniciativaSelecionada: 1,
                iniciativas: "",
                idLei: 0,

                iconDropdown: mdiChevronDown,
                iconeItem1: "fas fa-times text-warning",
                iconeItem2: "fas fa-times text-warning",
                iconeItem3: "fas fa-times text-warning",
                iconeItem4: "fas fa-times text-warning",
            })
        },
        components: {
            NavComponent,
            HeaderPadrao,
            FloatButton
        },
        methods: {
            getGrupo: function () {
                var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                api.get("/Usuario/GetUserData?hash=" + cookie)
                    .then(response => {
                        var user = response.data;
                        this.user = user;
                        this.grupo = user.usuario_Grupos.map(e => { return e.idGrupo });
                    })
                    .catch(error => {
                        this.$swal("Oops!", error.response.data, "error");
                    });

            },
            salvar: async function () {
                if (this.idOpcaoAtual == 0) {
                    this.ficha.descricao = "Constituição Estadual";
                    api.post("/Constituicao/AddFicha", this.ficha)
                    .then(result => {
                        this.$swal("Sucesso", result.data.mensagem, "success")
                        .then(response => {
                            this.idLei = result.data.id,
                            this.idOpcaoAtual++;
                        });
                    })
                    .catch(error => {
                        if (error.response.status == 400) {
                            this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                        }
                        else this.$swal("Erro", error.response.data, "error");
                    });
                }
                else if (this.idOpcaoAtual == 1) {
                    let formData = new FormData();
                    formData.append('id', this.idLei);
                    if (this.content.arquivo !== null)
                        formData.append('arquivo', this.content.arquivo);
                    if (this.content.texto !== this.txtorig)
                        formData.append('texto', this.content.texto);
                    
                    if (this.content.arquivo !== null || this.content.texto !== this.txtorig) {
                        await api.post("/Constituicao/AddConteudo", formData)
                        .then(result => {
                            this.$swal("Sucesso", result.data.mensagem, "success")
                            .then(response => {
                                this.idOpcaoAtual++;
                            });
                        })
                        .catch(error => {
                            this.$swal("Error", error.response.data, "error");
                        });
                    }
                }
                else if (this.idOpcaoAtual == 2) {
                    let formData = new FormData();
                    formData.append('id', this.idLei);
                    if (this.content.arquivoAtualizado !== null)
                        formData.append('arquivoAtualizado', this.content.arquivoAtualizado);
                    if (this.content.textoAtualizado !== this.txtcons)
                        formData.append('textoAtualizado', this.content.textoAtualizado);
                    
                    if (this.content.arquivoAtualizado !== null || this.content.textoAtualizado !== this.txtcons) {
                        await api.post("/Constituicao/AddConteudo", formData)
                        .then(result => {
                            this.$swal("Sucesso", result.data.mensagem, "success")
                            .then(response => {
                                this.idOpcaoAtual++;
                            });
                        })
                        .catch(error => {
                            this.$swal("Error", error.response.data, "error");
                        });
                    }
                }
            },
            fillSelects: function () {
                api.get("/Iniciativa/Get").then(result => {
                    this.iniciativas = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });

                api.get("/Situacao/Get").then(result => {
                    this.situacoes = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            updateIndicators: function () {
                if (this.idOpcaoAtual == 0) {
                    this.iconeItem1 = "fas fa-times text-warning";
                    this.iconeItem2 = "fas fa-times text-warning";
                    this.iconeItem3 = "fas fa-times text-warning";
                    this.iconeItem4 = "fas fa-times text-warning";
                }
                else if (this.idOpcaoAtual == 1) {
                    this.iconeItem1 = "fas fa-check text-success";
                    this.iconeItem2 = "fas fa-times text-warning";
                    this.iconeItem3 = "fas fa-times text-warning";
                    this.iconeItem4 = "fas fa-times text-warning";
                }
                else if (this.idOpcaoAtual == 2) {
                    this.iconeItem1 = "fas fa-check text-success";
                    this.iconeItem2 = "fas fa-check text-success";
                    this.iconeItem3 = "fas fa-times text-warning";
                    this.iconeItem4 = "fas fa-times text-warning";
                }
                else if (this.idOpcaoAtual == 3) {
                    this.iconeItem1 = "fas fa-check text-success";
                    this.iconeItem2 = "fas fa-check text-success";
                    this.iconeItem3 = "fas fa-check text-success";
                    this.iconeItem4 = "fas fa-times text-warning";
                }
                else {
                    this.iconeItem1 = "fas fa-check text-success";
                    this.iconeItem2 = "fas fa-check text-success";
                    this.iconeItem3 = "fas fa-check text-success";
                    this.iconeItem4 = "fas fa-check text-success";
                }
            },
            goTo: function (x) {
                this.idOpcaoAtual = x;
            }
        },
        beforeMount: function () {
            var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            if (cookie) this.getGrupo();
        },
        mounted: function () {
            this.fillSelects();
        },
        updated: function () {
            this.updateIndicators();
        }
        /* eslint-enable */
    }
</script>

<style>
    input {
        background: #FFF !important;
    }
    ::-webkit-input-placeholder {
        color: #495057 !important;
    }
    .container-input-file {
        width: 500px;
        clear: both;
    }
    .container-input-file input {
        width: 100%;
        clear: both;
    }
</style>

