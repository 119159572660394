<template>
  <v-btn
    variant="tonal"
    color="#0d8c1b"
    class="ml-4 p-4"
    style="color: #fff; width: 200px; text-decoration: none;"
    @click="redirect()"
  >
    <span class="bold-title">
      {{ texto }}
    </span>
    <v-icon color="#fff" x-large>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "BtnExterno",
  data: () => {
    return {
      linkAnalytics: "https://analytics.google.com/analytics/web/?authuser=2#/p318137636/reports/reportinghub",
    };
  },
  props: {
    texto: String,
    icon: String,
  },
  methods: {
    redirect: function() {
      // sem href reativo ;-;
      window.open(this.linkAnalytics);
    }
  },
  mounted: function() {},
};
</script>

<style></style>
