<template>
    <div id="HeaderDestaque" class="container">
        <div id="logoImgs" class="container">
            <img id="logo" class="img-fluid" src="../../assets/imgs/logoLegison.svg" alt="Logo Legison" /><br />
            <img id="subtitulo" class="img-fluid" src="../../assets/imgs/subtituloLegison.svg" alt="Área administrativa" />
        </div>

        
    </div>
</template>

<script>
    export default {
        name: 'HeaderDestaque',
        components: {
        }
    }
</script>

<style>
    #HeaderDestaque {
        margin-top: 120px
    }

    #logo {
        margin-bottom: 15px
    }

    #subtitulo {
        width:200px;
    }

    #compBusca {
        margin-top: 98px;
    }

    footer {
        clear: both;
        position: relative;
        height: 200px;
        margin-top: 0;
    }
</style>
