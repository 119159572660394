<template>
<!-- eslint-disable -->
    <div class="AddAtos">
        <NavComponent id="NavComponent"></NavComponent>

        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="boxContent">
                        <div class="container text-left">
                            <br />
                            <h3 class="bold-title"><i class="fas fa-file-signature"></i> Adicionando Remissão</h3>
                            <hr />
                            <div class="row">
                                <div class="col-md-3" style="border-right: 1px solid rgb(226 226 226)">
                                    <i :class="iconeItem1"></i> Cadastro de Remissão  <br />
                                </div>
                                <div class="col-md-9">

                                    <form>
                                        <div class="row form-group" v-if="idOpcaoAtual < 2">
                                            <div class="col-md-6">
                                                <label>Número do Ato Ativo</label>
                                                <input type="text" v-model="fichaProcura.numAtiva" class="form-control" />
                                            </div>

                                            <div class="col-md-6">
                                                <label>Número do Ato Passivo</label>
                                                <input type="text" v-model="fichaProcura.numPassiva" class="form-control" />
                                            </div>

                                            <div v-if="ativas || passivas" class="d-flex col-md-12">
                                                <div class="col-6" v-if="ativas.length !== 0">
                                                    <label>Selecione o Ato Ativo:</label>
                                                    <v-autocomplete
                                                        solo
                                                        clearable
                                                        v-model="ficha.numAtiva"
                                                        no-data-text="Não foi encontrado um ato com o número pesquisado."
                                                        :menu-props="{ transition: 'scroll-y-transition' }"
                                                        :items="ativas"
                                                        :item-text="item => item.tipoAto.descricao + ' Nº '  + item.numero + (moment(item.dataAto).format('DD/MM/YYYY') !== '01/01/0001' ? ' - ' + moment(item.dataAto).format('DD/MM/YYYY') : '')" 
                                                        :item-value="'id'">
                                                        <template v-slot:append>
                                                            <v-icon style="cursor: pointer">{{ iconDropdown }}</v-icon>
                                                        </template>
                                                    </v-autocomplete>
                                                </div>

                                                <div class="col-6" v-if="passivas.length !== 0">
                                                    <label>Selecione o Ato Passivo:</label>
                                                    <v-autocomplete
                                                        solo
                                                        clearable
                                                        v-model="ficha.numPassiva"
                                                        no-data-text="Não foi encontrado um ato com o número pesquisado."
                                                        :menu-props="{ transition: 'scroll-y-transition' }"
                                                        :items="passivas"
                                                        :item-text="item => item.tipoAto.descricao + ' Nº '  + item.numero + (moment(item.dataAto).format('DD/MM/YYYY') !== '01/01/0001' ? ' - ' + moment(item.dataAto).format('DD/MM/YYYY') : '')" 
                                                        :item-value="'id'">
                                                        <template v-slot:append>
                                                            <v-icon style="cursor: pointer">{{ iconDropdown }}</v-icon>
                                                        </template>
                                                    </v-autocomplete>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <label>Ação</label>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <select class="form-control" v-model="ficha.acao">
                                                            <option value="0" selected>Selecione</option>
                                                            <option v-for="acao in acoes" :value="acao.id">{{ acao.descricao }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="idOpcaoAtual == 2" class="text-center">
                                            <br />
                                            <i class="fas fa-check text-success fa-3x"></i>
                                            <br />
                                            <br />
                                            <h3>Remissão cadastrada com sucesso.</h3>
                                        </div>
                                        <br />

                                        <a @click="procurar()" v-if="idOpcaoAtual >= 0 && idOpcaoAtual < 2" href="#" class="btn btn-info btn-block">{{ opcaoAtual[0].texto }}</a>

                                        <a @click="salvar()" v-if="idOpcaoAtual == 1" href="#" class="btn btn-info btn-block">{{ opcaoAtual[1].texto }}</a>

                                        <a @click="idOpcaoAtual--" href="#" v-if="idOpcaoAtual >= 2" class="btn btn-sm btn-info btn-block">Voltar</a>
                                    </form>

                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
            </div>
        </div>
    </div>
<!-- eslint-enable -->
</template>

<script>
    /* eslint-disable */
    import NavComponent from '../../../components/shared/NavComponent.vue';
    import HeaderPadrao from '../../../components/shared/HeaderPadrao.vue';
    import { mdiChevronDown } from '@mdi/js';

    export default {
        name: 'AddAtos',
        data: () => {
            return ({
                tipos: null,
                checkeda: null,
                checkedp: null,
                ficha: {
                    numAtiva: null,
                    numPassiva: null,
                    acao: 0,
                },
                fichaProcura: {
                    numAtiva: "",
                    numPassiva: "",
                    acao: 0,
                },
                acoes: "",
                idOpcaoAtual: 0,
                isAddingUsuario: false,
                opcaoAtual: [{ id: 0, texto: "Procurar Atos" },
                { id: 1, texto: "Cadastrar Remissão" },
                { id: 2, texto: "Salvar remissão" }],
                pesquisa: "",
                ativas: null,
                passivas: null,
                user: null,
                iconDropdown: mdiChevronDown,
                iconeItem1: "fas fa-times text-warning",
                iconeItem2: "fas fa-times text-warning",
                iconeItem3: "fas fa-times text-warning",
            })
        },
        components: {
            NavComponent,
            HeaderPadrao,
        },
        methods: {
            getUser: function () {
                var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                api.get("/Usuario/GetUserData?hash=" + cookie)
                .then(response => {
                    this.user = response.data;
                })
                .catch(error => {
                    this.$swal("Oops!", error.response.data, "error");
                })
            },
            procurar: function () {
                if (this.fichaProcura.numAtiva === null || this.fichaProcura.numPassiva === null || this.fichaProcura.numAtiva === "" || this.fichaProcura.numPassiva === "") {
                    this.$swal("Erro", "Insira o número dos Atos para fazer a procura.", "error");
                } 
                else {
                    api.post("/Remissao/Procurar", this.fichaProcura)
                    .then(result => {
                        this.ativas = result.data.ativa;
                        this.checkeda = this.ativas.map(e => { return { id: e.id, state: false } });
                        this.passivas = result.data.passiva;
                        this.checkedp = this.passivas.map(e => { return { id: e.id, state: false } });
                        this.ficha.numAtiva = null;
                        this.ficha.numPassiva = null;
                        if (this.idOpcaoAtual < 1) this.idOpcaoAtual++;
                    })
                    .catch(error => {
                        if (error.response.status == 400) {
                            this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                        }
                        else this.$swal("Erro", error.response.data, "error");
                    });
                }
            },
            salvar: function () {
                if (this.ficha.acao == 0 || this.ficha.acao == '0') {
                    this.$swal("Erro", "Selecione uma Ação.", "error");
                }
                else
                    if (this.ficha.numAtiva === null || this.ficha.numPassiva === null) {
                    this.$swal("Erro", "Você não selecionou todos os atos.", "error");
                } else 
                if (this.ficha.numAtiva === this.ficha.numPassiva) {
                    this.$swal("Erro", "Você não fazer uma remissão entre o mesmo ato.", "error");
                } else {
                    api.post("/Remissao/AddRemissao", this.ficha)
                    .then(result => {
                        this.$swal("Sucesso", result.data.mensagem, "success").then(response => {
                            this.idOpcaoAtual++;
                        });
                    })
                    .catch(error => {
                        if (error.response.status == 400) {
                            this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                        }
                        else this.$swal("Erro", error.response.data, "error");
                    });
                }
            },
            fillSelects: function () {
                api.get("/Acao/Get")
                .then(result => {
                    this.acoes = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
                api.get("/TipoAto/Get")
                .then(result => {
                    this.tipos = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            updateIndicators: function () {
                if (this.idOpcaoAtual == 0) {
                    this.iconeItem1 = "fas fa-times text-warning";
                    this.iconeItem2 = "fas fa-times text-warning";
                    this.iconeItem3 = "fas fa-times text-warning";
                }
                else if (this.idOpcaoAtual == 1) {
                    this.iconeItem1 = "fas fa-check text-success";
                    this.iconeItem2 = "fas fa-times text-warning";
                    this.iconeItem3 = "fas fa-times text-warning";
                }
                else if (this.idOpcaoAtual == 2) {
                    this.iconeItem1 = "fas fa-check text-success";
                    this.iconeItem2 = "fas fa-check text-success";
                    this.iconeItem3 = "fas fa-times text-warning";
                }
                else {
                    this.iconeItem1 = "fas fa-check text-success";
                    this.iconeItem2 = "fas fa-check text-success";
                    this.iconeItem3 = "fas fa-check text-success";
                }
            }
        },
        mounted: function () {
            this.fillSelects();
            var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            if (cookie) this.getUser();
        },
        updated: function () {
            this.updateIndicators();
        }
    }
    /* eslint-enable */
</script>

