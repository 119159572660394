<template>
    <div class="Home">
        <NavComponent id="NavComponent"></NavComponent>

        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="boxContent">
                        <div class="container text-left">
                            <br />
                            <h3 class="bold-title"><i class="fas fa-file-import"></i>Cadastros</h3>
                            <hr />
                            <br />
                            <div class="row flex-row same-height">

                                <div class="d-flex col-md-12">
                                    <div class="col-md-6">
                                        <button type="button" @click="$router.push('/Cadastros/Atos/Adicionar')" class="btn btn-primary btn-block btn-lg">
                                            <i class="fas fa-file-signature"> </i> <br />
                                            Atos <br />
                                            <small>Emendas constitucionais, Leis, Decretos e Pareceres</small>
                                        </button>
                                    </div>  

                                    <div class="col-md-6">
                                        <button type="button" @click="$router.push('/Cadastros/Remissoes')" class="btn btn-primary btn-block btn-lg">
                                            <i class="fas fa-file-export"></i> <br />
                                            Remissões <br />
                                            <small>Remissões Ativas e Passivas de Atos</small>
                                        </button>
                                    </div>
                                </div>

                            </div>

                            <br />

                            <h3>Tipos</h3>

                            <br />

                            <div class="row flex-row same-height">
                                <div class="col-md-3">
                                    <button type="button" @click="$router.push('/Cadastros/Tipos/Atos')" class="btn btn-primary btn-block btn-lg">
                                        <i class="fas fa-list-ol"></i> <br />
                                        Atos
                                    </button>
                                </div>

                                <div class="col-md-3">
                                    <button type="button" @click="$router.push('/Cadastros/Tipos/Iniciativas')" class="btn btn-primary btn-block btn-lg">
                                        <i class="fas fa-list-ol"></i> <br />
                                        Iniciativas
                                    </button>
                                </div>
                                
                                <div class="col-md-3">
                                    <button type="button" @click="$router.push('/Cadastros/Tipos/Orgaos')" class="btn btn-primary btn-block btn-lg">
                                        <i class="fas fa-list-ol"></i> <br />
                                        Órgãos
                                    </button>
                                </div>

                                <div class="col-md-3">
                                    <button type="button" @click="$router.push('/Cadastros/Tipos/Temas')" class="btn btn-primary btn-block btn-lg">
                                        <i class="fas fa-list-ol"></i> <br />
                                        Temas
                                    </button>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center"> </div>
        </div>
               
    </div>
</template>

<script>
    import NavComponent from '../../components/shared/NavComponent.vue';
    import HeaderPadrao from '../../components/shared/HeaderPadrao.vue';

    export default {
        name: 'Home',
        data: () => {
            return ({
            })
        },
        components: {
            NavComponent,
            HeaderPadrao
        },
        methods: {
        },
        mounted: function () {
        }
    }
</script>

