<template>
    <div id="HeaderPadrao" class="container">

        <div id="logoImgs" class="container">
            <img id="logo-header" class="img-fluid" src="../../assets/imgs/logoLegison.svg" alt="Logo Legison" /><br />
        </div>

        <nav class="container header-breadcrumb" id="breadcrumb" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><i class="fas fa-home"></i></li>
                <li v-for="path in paths" :key="path" class="breadcrumb-item">{{path}}</li>
            </ol>
        </nav>

    </div>

</template>

<script>
    export default {
        name: 'HeaderPadrao',
        data() {
            return {
                show: false,
                paths: ""
            }
        },
        mounted: function () {
            this.paths = this.$route.path.substring(1).split('/');
        },
        components: {
        }
    }
</script>
<style>
    @import '../../assets/styles/components/headerPadrao.css';
</style>