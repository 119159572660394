<template>
    <div class="Home">
        <NavComponent id="NavComponent"></NavComponent>

        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="boxContent">
                        <div class="container text-left">
                            <!-- eslint-disable -->
                            <br />
                            <h3 class="bold-title"><i class="fas fa-search"></i> Consulta de Atos</h3>
                            
                            <hr />

                            <div class="card">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <label>Número</label>
                                      <input type="text" v-model="pesquisa.numero" class="form-control" />
                                    </div>

                                    <div class="col-md-3">
                                      <label>Usuário criador</label>
                                      <select class="form-control" v-model="pesquisa.idUsuarioCriador">
                                        <option value="0">Selecione</option>
                                        <option v-for="usuario in usuarios" :value="usuario.id">{{ usuario.nome }}</option>
                                      </select>
                                    </div>

                                    <div class="col-md-3">
                                      <label>Assinatura</label>
                                      <select v-if="pesquisa.idTipo === '5'" class="form-control" v-model="pesquisa.assinatura" disable> 
                                        <option disable value="">Não se aplica</option>
                                      </select> 
                                      <select v-else class="form-control" v-model="pesquisa.assinatura">
                                        <option value="">Selecione</option>
                                        <option value="Incompleto">Incompleto</option>
                                        <option value="Completo">Completo</option>
                                      </select>
                                    </div>

                                    <div class="col-md-3">
                                        <label>Disponibilidade</label>
                                        <select class="form-control" v-model="pesquisa.disponivel">
                                            <option value="">Selecione</option>
                                            <option value="disp">Disponível ao Público</option>
                                            <option value="indips">Indisponível ao Público</option>
                                        </select>
                                        <!--<v-switch class="mt-0 pt-0" v-model="pesquisa.disponivel" inset color="primary" :label="pesquisa.disponivel ? 'Disponível' : 'Indisponível'" />-->
                                    </div>

                                    <div class="col-md-3">
                                      <label>Tipo</label>
                                      <select class="form-control" v-model="pesquisa.idTipo" @change="toggleLoads($event.target.value)">
                                        <option value="0">Selecione</option>
                                        <option v-for="tipo in tipos" :value="tipo.id">{{ tipo.descricao }}</option>
                                      </select>
                                    </div>

                                    <div class="col-md-3">
                                      <label>Iniciativa</label>
                                      <select class="form-control" v-model="pesquisa.idIniciativa">
                                        <option value="0">Selecione</option>
                                        <option v-for="iniciativa in iniciativas" :value="iniciativa.id">{{ iniciativa.descricao }}</option>
                                      </select>
                                    </div>

                                    <div class="col-md-3">
                                      <label>Tema</label>
                                      <select class="form-control" v-model="pesquisa.idTema">
                                        <option value="0">Selecione</option>
                                        <option v-for="tema in temas" :value="tema.id">{{ tema.descricao }}</option>
                                      </select>
                                    </div>

                                    <div class="col-md-3">
                                      <label>Órgão</label>
                                      <select class="form-control" v-model="pesquisa.idOrgao">
                                        <option value="0">Selecione</option>
                                        <option v-for="orgao in orgaos" :value="orgao.id">{{ orgao.nome }}</option>
                                      </select>
                                    </div>

                                    <div class="col-md-6">
                                      <label>Ementa</label>
                                      <input type="text" v-model="pesquisa.ementa" class="form-control" />
                                    </div>

                                    <div class="col-md-4">
                                      <label>Palavras-chave</label>
                                      <input type="text" v-model="pesquisa.palavrasChave" class="form-control" />
                                    </div>

                                    <div class="col-md-2">
                                      <label>Situação</label>
                                      <select class="form-control" v-model="pesquisa.idSituacao">
                                        <option value="0">Selecione</option>
                                        <option v-for="sit in situacoes" :value="sit.id">{{ sit.descricao }}</option>
                                      </select>
                                    </div>

                                    <div class="col-md-12">
                                      <br />
                                      <a v-on:click="pesquisar()" class="btn btn-info btn-block">Consultar</a>
                                    </div>
                                  </div>
                                </div>
                            </div>

                            <br />

                            <div class="d-flex flex-column">
                                <div class="row justify-content-center d-flex col-12">
                                    <v-btn v-if="getSearchOrder === 'desc' && !matchApplied" :id="'desc'" text color="primary" @click="toggleSort(1)">
                                        <v-icon>{{ icons.mdiSortDesc }}</v-icon>
                                    </v-btn>
                                    <v-btn v-else text color="#444" :id="'desc'" @click="toggleSort(1)">
                                        <v-icon>{{ icons.mdiSortDesc }}</v-icon>
                                    </v-btn>
                                    <b-tooltip :target="'desc'" triggers="hover">
                                        Mais Recente.
                                    </b-tooltip>

                                    <v-btn v-if="getSearchOrder === 'asc'" :id="'asc'" text color="primary" @click="toggleSort(2)">
                                        <v-icon>{{ icons.mdiSortAsc }}</v-icon>
                                    </v-btn>
                                    <v-btn v-else :id="'asc'" text color="#444" @click="toggleSort(2)">
                                        <v-icon>{{ icons.mdiSortAsc }}</v-icon>
                                    </v-btn>
                                    <b-tooltip :target="'asc'" triggers="hover">
                                        Mais Antigo.
                                    </b-tooltip>

                                    <div v-if="pesquisa !== null && pesquisa !== '' && pesquisa.numero !== ''">
                                        <v-btn v-if="getSearchOrder === 'match' || matchApplied" :id="'match'" text color="primary" @click="toggleSort(3)">
                                            <v-icon>{{ icons.mdiSortSearch }}</v-icon>
                                        </v-btn>
                                        <v-btn v-else :id="'match'" text color="#444" @click="toggleSort(3)">
                                            <v-icon>{{ icons.mdiSortSearch }}</v-icon>
                                        </v-btn>
                                        <b-tooltip :target="'match'" triggers="hover">
                                            Relevância.
                                        </b-tooltip>
                                    </div>

                                    <div v-else>
                                        <v-btn disabled><v-icon>{{ icons.mdiSortSearch }}</v-icon></v-btn>
                                    </div>
                                    
                                </div>

                                <div v-if="consulta" class="col-12 justify-content-center d-flex align-items-center" style="height: 34px; color: #066910; font-weight: 700">
                                    <span style="margin-right: 12px;">{{qtdAtos.toLocaleString({minimumIntegerDigits: 2})}} Ato{{qtdAtos != 1 ? 's' : ''}} encontrado{{qtdAtos != 1 ? 's' : ''}}!</span>
                                </div>

                            </div>

                            <div class="row justify-content-end mb-4" id="unseen" v-if="consti">
                                <CardConst />
                                <br />
                            </div>
                            <div class="row justify-content-end mb-4" id="unseen" v-for="ato in atoArr">
                                <CardAto :ato="ato" />
                                <br />
                            </div>

                            <center>
                                <v-btn v-if="displaySeeMore"
                                       plain
                                       elevation="0"
                                       color="#888"
                                       width="300"
                                       @click="handleLoadMore"
                                       class="mt-6">
                                    Ver mais
                                </v-btn>
                            </center>

                            <!-- eslint-enable -->
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
            </div>
        </div>

    </div>
</template>

<script>
    import NavComponent from '../../components/shared/NavComponent.vue';
    import HeaderPadrao from '../../components/shared/HeaderPadrao.vue';
    import CardAto from './components/CardAto.vue';
    import CardConst from './components/CardConst.vue';
    import { mdiSortCalendarAscending, mdiSortCalendarDescending, mdiFormatLetterMatches } from '@mdi/js';

    export default {
        /* eslint-disable */
        name: 'Home',
        data: () => {
            return ({
                atoid: null,
                consti: false,
                pesquisa: {
                    numero: "",
                    ementa: "",
                    palavrasChave: "",
                    idTipo: 0,
                    idIniciativa: 0,
                    idTema: 0,
                    idOrgao: 0,
                    idSituacao: 0,
                    idUsuarioCriador: 0,
                    count: 0,
                    results: 10,
                    order: "",
                    assinatura: "",
                    disponivel: ""
                },
                loadSort: "desc",
                loadType: "",
                matchApplied: false,
                icons: {
                    mdiSortAsc: mdiSortCalendarAscending,
                    mdiSortDesc: mdiSortCalendarDescending,
                    mdiSortSearch: mdiFormatLetterMatches
                },
                tipos: "",
                temas: "",
                orgaos: "",
                atos: "",
                situacoes: "",
                iniciativas: "",
                usuarios: "",
                atoArr: [],
                request: 0,
                listener: 0,
                user: null,
                qtdAtos: 0,
                consulta: false,
            })
        },
        components: {
            NavComponent,
            HeaderPadrao,
            CardAto,
            CardConst,
        },
        methods: {
            assinatura: function (id) {
                this.atoid = id;
                api.post("/Ato/ToggleAssinatura?atoid=" + this.atoid)
                .then(result => {
                    this.atoArr.map(x => {
                        if (x.id === this.atoid) x.assinatura = result.data.ass
                    });
                    this.$swal("Sucesso", result.data.message, "success");
                })
                .catch(error => {
                    this.$swal("Erro", error.data, "error");
                });
            },
            fillSelects: function () {
                api.get("/Iniciativa/Get").then(result => {
                    this.iniciativas = result.data;
                }).catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });

                api.get("/Orgao/Get").then(result => {
                    this.orgaos = result.data;
                }).catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });

                api.get("/Tema/Get").then(result => {
                    this.temas = result.data;
                }).catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });

                api.get("/Situacao/Get").then(result => {
                    this.situacoes = result.data;
                }).catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });

                api.get("/TipoAto/Get").then(result => {
                    var types = result.data.filter(x => x.permiteCadastro);
                    var emenda = types.find(x => x.descricao.toLowerCase().startsWith('emenda'));
                    emenda.descricao = "Constituição Estadual e Emendas";
                    this.tipos = types;
                }).catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });

                var cookie = this.$cookies.get("COOKIE_LEGISON_TOKEN");
                api.get("/Usuario/GetAndData?hash=" + cookie).then(result => {
                    this.usuarios = result.data.users;
                    this.user = result.data.whoRequested;
                }).catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            load: function () {
                if (this.pesquisa.idTipo === "8") this.consti = true;
                api.post("/Ato/Consulta", this.pesquisa).then(result => {
                    this.atos = result.data.item1;
                    this.qtdAtos = result.data.item2;
                    if (this.listener == 0 && this.atos.length >= this.pesquisa.results) this.listener = 1;
                    else if (this.listener == 1 && this.atos.length < this.pesquisa.results) this.listener = 0;
                    else if (this.atos.length < this.pesquisa.results) this.listener = 0;
                    this.atoArr = this.atoArr.concat(this.atos);
                    this.pesquisa.count += this.pesquisa.results;
                    this.request = 1;
                }).catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            toggleLoads: function (type) {
                this.pesquisa.idTipo = type;
            },
            toggleSort: function (s) {
                if (s === 1) {
                  this.pesquisa.order =  "desc"; 
                  this.loadSort = "desc";
                  this.matchApplied = false;
                } 
                else if (s === 2) {
                    this.pesquisa.order =  "asc";
                    this.loadSort = "asc";
                    this.matchApplied = false;
                }
                else if (s === 3) {
                    if (this.pesquisa.order === "match") {
                        this.pesquisa.order = "desc";
                        this.loadSort = "desc";
                        this.matchApplied = false;
                    }
                    else {
                        this.pesquisa.order =  "match";
                        this.loadSort = "match";
                        this.matchApplied = true;
                    }
                }  
                this.pesquisar();
            },
            pesquisar: function () {
                if (!this.pesquisado && this.loadSort === "match") this.loadSort = "desc";
                else if (this.matchApplied && this.loadSort === "match") this.loadSort = "desc";
                this.atos = "";
                this.pesquisa.count = 0;
                this.atoArr = [];
                this.load();
                this.consulta = true;
            },
            remove: function (obj) {
                this.$swal({
                    title: "Confirme",
                    text: "Você tem certeza que deseja excluir o ato " + obj.numero + "?",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Remover",
                    cancelButtonText: "Cancelar"
                })
                .then(response => {
                    if (response.isConfirmed) {
                        api.delete("/Ato/Remove?id=" + obj.id)
                        .then(result => {
                            this.$swal("Sucesso", result.data, "success");
                            this.pesquisa.count = 0;
                            this.atoArr = [];
                            this.pesquisar();

                            api.delete("/Remissao/RemoveAll?idato=" + obj.id + "&uid=" + this.user.id)
                            .then(result => { })
                            .catch(err => { });
                        })
                        .catch(error => {
                            this.$swal("Erro", error.data, "error");
                        });
                    }
                });
            },
            handleLoadMore: function () {
                this.listener = 1;
                this.request = 0;
                this.load();
            },
            getResults: function () {
                this.listener = 1;
                this.load();
            },
            getUser: function () {
                var cookie = this.$cookies.get("COOKIE_LEGISON_TOKEN");
                api.get("/Usuario/GetUserData?hash=" + cookie)
                    .then(response => {
                        this.user = response.data;
                    })
                    .catch(error => {
                        this.$swal("Oops!", error.response.data, "error");
                    })
            }
        },
        mounted: function () {
            var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            if (cookie) this.fillSelects();
        },
        computed: {
            displaySeeMore: function () {
                return this.listener;
            },
            getSearchOrder: function () {
                return this.loadSort;
            }
        }

        /* eslint-enable */
    }
</script>
