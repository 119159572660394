import axios from "axios";



export const Api = axios.create({
  baseURL: "https://localhost:12127",
  // baseURL: "https://localhost:44397",
  // baseURL: "http://pge-srv-031:5004",
  //baseURL: "https://legison.pge.se.gov.br",
});

export const IsFirstAcess = async (hash) => {
    return Api
      .post("Usuario/IsFirstAcess?hash="+ hash)
      .then((result) => {
        return result;
      })
      .catch((e) => {
        return e.response;
      });
  };