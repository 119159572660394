export function prepareTitle(descricao, numero) {
    return `${descricao} n° ${!isNaN(numero) ? numero : numero}`;
}

export function prepareDate(dataPublicacao) {
    let date = Date.parse(dataPublicacao);
    let dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }
    let formatted = new Intl.DateTimeFormat('pt-BR', dateOptions).format(date);

    return `${formatted}`;
}

export function preparePath(name, tipoato, numero, ato) {
    return {
        name,
        params: {
            tipoato,
            numero,
            ato,
        }

    };
}