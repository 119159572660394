<template>
    <div class="ReportErros">
        <NavComponent id="NavComponent"></NavComponent>

        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="boxContent">
                        <div class="container text-left">
                            <br />

                            <h3 class="bold-title"><i class="fas fa-bug"></i> Erros</h3>

                            <br />

                            <div class="row">
                                <input type="text" @keyup="searchTimeout()" v-model="pesquisa" class="form-control" placeholder="Pesquise pelo tipo do erro" />
                            </div>

                            <br />

                            <div class="row" id="unseen">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">E-mail</th>
                                            <th scope="col">Tipo de Erro</th>
                                            <th scope="col">Data do Relato</th>
                                            <th scope="col">Ações</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <!-- eslint-disable -->
                                        <tr v-for="erro in errosArr">
                                            <td>{{ erro.id }}</td>
                                            <td>{{ erro.email }}</td>
                                            <td>{{ erro.tipo }}</td>
                                            <td>{{ convertDateTime(erro.dataRelato) }}</td>
                                            <td>
                                                <a class="mr-2" href="#" @click="detailsErro(erro)">
                                                    <DetailsErro :erro="erro"/>
                                                    <!-- <i class="fas fa-eye"></i> -->
                                                </a>
                                                <a href="#" @click="() => removeErro(erro)">
                                                    <v-icon color="#DC3545" size="16px" class="fas fa-trash"></v-icon>

                                                </a>
                                            </td>
                                        </tr>
                                        <!-- eslint-enable -->
                                    </tbody>
                                </table>
                            </div>
                            <center>
                                <!-- <v-btn v-if="displaySeeMore"
                                       plain
                                       :loading="fetching"
                                       elevation="0"
                                       color="#888"
                                       width="300"
                                       @click="handleLoadMore"
                                       class="mt-6">
                                    Ver mais
                                </v-btn>
                                -->
                                <v-btn v-if="displaySeeMore"
                                       plain
                                       elevation="0"
                                       color="#888"
                                       width="300"
                                       @click="handleLoadMore"
                                       class="mt-6">
                                    Ver mais
                                </v-btn>
                            </center>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
            </div>
        </div>

        <!-- <a @click="addUsuario()" class="btnFloat">
            <FloatButton id="FloatButton" icon="fas fa-plus"></FloatButton>
        </a> -->
    </div>
</template>

<script>
    /* eslint-disable */

    import FloatButton from '../../../components/shared/FloatButton.vue';
    import NavComponent from '../../../components/shared/NavComponent.vue';
    import HeaderPadrao from '../../../components/shared/HeaderPadrao.vue';
    import DetailsErro from './components/DetailsErro.vue';

    export default {
        name: 'ReportErros',
        data: () => {
            return ({
                isAddingUsuario: false,
                erros: "",
                pesquisa: "",
                usuarioEditando: "",
                count: 0,
                results: 5,
                request: 0,
                listener: 1,
                errosArr: [],
                visualizandoErro: "",
            })
        },
        components: {
            NavComponent,
            HeaderPadrao,
            FloatButton,
            DetailsErro
        },
        computed: {
            displaySeeMore: function () {
                return this.listener;
            },
        },
        methods: {
            resetload: function () {
                this.count = 0;
                this.errosArr = [];
                this.load();
            },
            searchTimeout: function () {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null
                }
                this.timeout = setTimeout(() => {
                    this.pesquisar();
                }, 800);
            },
            convertDateTime(dateTimeString) {
                const originalDate = new Date(dateTimeString);

                const dia = String(originalDate.getDate()).padStart(2, '0');
                const mes = String(originalDate.getMonth() + 1).padStart(2, '0');
                const ano = originalDate.getFullYear();

                const hora = String(originalDate.getHours()).padStart(2, '0');
                const minuto = String(originalDate.getMinutes()).padStart(2, '0');
                const segundo = String(originalDate.getSeconds()).padStart(2, '0');

                const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;
                return dataFormatada;
            },
            load: function () {
                api.get("/Report/GetX?pesquisa=" + this.pesquisa + "&count=" + this.count + "&results=" + this.results)
                .then(result => {
                    this.erros = result.data;
                    if (this.erros.length < this.results) this.listener = 0;
                    if (this.listener == 0 && this.erros.length >= this.results) this.listener = 1;
                    this.errosArr = this.errosArr.concat(this.erros);
                    this.count += this.results;
                    this.request = 1;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            pesquisar: function () {
                this.erros = "";
                this.count = 0;
                this.errosArr = [];
                this.load();
            },
            // addUsuario: function () {
            //     this.$modal.show('AddUsuario')//.then(response => { this.load(); });
            // },
            detailsErro: function (erro) {
                this.visualizandoErro = erro;
            },
            removeErro: function (erro) {
                this.$swal({
                    title: "Confirme",
                    text: "Você tem certeza que deseja excluir o erro " + erro.id + "?",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Remover',
                    cancelButtonText: 'Cancelar'
                })
                .then(response => {
                    if (response.isConfirmed) {
                        var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                        api.delete("/Report/Remove?erroId=" + erro.id) 
                        .then(result => {
                            this.$swal("Sucesso", result.data, "success");
                            var index = this.errosArr.findIndex(e => e.id === erro.id);
                            if (index > -1) this.errosArr.splice(index, 1);
                            this.load();
                        })
                        .catch(error => {
                            this.$swal("Erro", error.data, "error");
                        });
                    }
                });
            },
            handleLoadMore: function () {
                this.listener = 1;
                this.request = 0;
                this.load();
            },
            getResults: function () {
                this.listener = 1;
                this.load();
            }
        },
        mounted: function () {
            this.getResults();
        }
    }
    /* eslint-enable */
</script>

