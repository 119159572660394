<template>
    <v-btn class="app-button" :color="color" @click="handleClick"><slot></slot></v-btn>
  </template>
  
  <script>
  export default {
      name: "AppButton",
  
      props: {
          color: {
              type: String,
              default: "primary"
          }
      },
  
      methods: {
          handleClick: function(event) {
              this.$emit('click', event);
          }
      }
  }
  </script>
  
  <style>
  .app-button {
      width: 100%;
  }
  </style>
  