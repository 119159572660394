<template>
    <div class="AddAcesso">
        <modal name="AddAcesso" height="auto">
            <br />
            <div class="container">
                <div class="text-left">
                    <h4>Adicionando acesso</h4>
                    <hr />
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Endereço<span class="text-danger">*</span></label>
                                <input @keyup.enter="salvarAcesso()" type="text" v-model="endereco" class="form-control" />
                            </div>
                        </div>
                        <br />
                        <a @click="salvarAcesso()" href="#" class="btn btn-info btn-block">Salvar</a>
                    </form>
                    <br />
                </div>
            </div>

        </modal>
    </div>
</template>

<script>
    /* eslint-disable */
    export default {
        name: 'AddAcesso',
        props: ["parent"],
        data: () => {
            return ({
                endereco: "/",
            })
        },
        methods: {         
            salvarAcesso: function () {
                api.post("/Acesso/Add" + "?uid=" + this.$props.parent.user.id, {
                    endereco: this.endereco,
                    idGrupo: this.$props.parent.grupoSelecionado
                }).then(result => {
                    this.$swal("Sucesso", result.data, "success").then(response => {
                        this.clearFields();
                        this.$props.parent.resetload();
                        this.$props.parent.loadAcessos();
                        this.$modal.hide('AddAcesso');
                    });
                }).catch(error => {
                    if (error.response.status == 400) {
                        this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                    }
                    else this.$swal("Erro", error.response.data, "error");
                });
            },
            clearFields: function () {
                this.nome = "";
            }

        },
        mounted() {
        },
        updated() {

        }
    };
    /* eslint-enable */
</script>