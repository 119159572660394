<template>
    <div class="EditOrgao">
        <modal name="EditOrgao" height="auto">
            <br />
            <div v-if="orgao != ''" class="container">
                <div class="text-left">
                    <h4>Editando {{ orgao.nome }}</h4>
                    <hr />
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Nome</label>
                                <input @keyup.enter="salvar()" type="text" v-model="orgao.nome" class="form-control" />
                            </div>                            
                        </div>
                        <br />
                        <a v-on:click="salvar()" href="#" class="btn btn-info btn-block">Salvar</a>
                    </form>
                    <br />
                </div>
            </div>

        </modal>
    </div>
</template>

<script>
    /* eslint-disable */
    export default {
        name: 'EditOrgao',
        props: ["orgao"],
        data: () => {
            return ({
                user: null
            })
        },
        methods: {           
            getUser: function () {
                var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                api.get("/Usuario/GetUserData?hash=" + cookie)
                .then(response => {
                    this.user = response.data;
                })
                .catch(error => {
                    this.$swal("Oops!", error.response.data, "error");
                })
            },
            salvar: function () {
                api.post("/Orgao/Edit" + "?uid=" + this.user.id, {
                    id: this.$props.orgao.id,
                    nome: this.$props.orgao.nome,
                })
                .then(result => {
                    this.$swal("Sucesso", result.data, "success").then(response => {
                        this.$modal.hide('EditOrgao');
                    });
                })
                .catch(error => {
                    if (error.response.status == 400) {
                        this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                    }
                    else this.$swal("Erro", error.response.data, "error");
                });
            }
        },
        mounted() {
            var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            if (cookie) this.getUser();
        },
        updated() {
        }
    };
    /* eslint-enable */
</script>
