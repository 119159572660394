<template>
  <div class="AddNovidade">
    <modal name="AddNovidade" height="auto">
      <br />
      <div class="container">
        <div class="text-left">
          <h4>Adicionando novidade</h4>
          <hr />
          <form>
            <div class="row">
              <div class="col-md-12">
                <label>Descrição<span class="text-danger">*</span></label>
                <textarea
                  @keyup.enter="salvar()"
                  type="text"
                  v-model="descricao"
                  class="form-control"
                  rows="4"
                ></textarea>
              </div>
            </div>
            <div class="row mt-0 align-items-end justify-content-between">
              <div class="col-md-4">
                <label>Data<span class="text-danger">*</span></label>
                <input
                  @keyup.enter="salvar()"
                  type="date"
                  v-model="data"
                  class="form-control"
                  :max="maxData"
                />
              </div>
              <div class="col-md-4">
                <label class="mb-1">
                  Funcionalidade?<span class="text-danger">*</span>
                </label>
                <v-switch
                  hide-details
                  class="mt-0"
                  v-model="feature"
                  :label="`${featureSwitch}`"
                />
              </div>
              <div class="col-md-4">
                <a @click="salvar()" href="#" class="btn btn-info btn-block"
                  >Salvar</a
                >
              </div>
            </div>
          </form>
        </div>
      </div>
      <br />
    </modal>
  </div>
</template>

<script>
import moment from "moment";
/* eslint-disable */
export default {
  name: "AddNovidade",
  props: ["parent"],
  data: () => {
    return {
      descricao: "",
      feature: false,
      data: "",
      maxData: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    featureSwitch: function() {
      return this.feature === false ? "Não" : "Sim";
    },
  },

  methods: {
    isAfterMaxDate: function() {
      return moment(this.data).isAfter(moment().format("YYYY-MM-DD"));
    },
    salvar: async function() {
      if (!this.isAfterMaxDate()) {
        console.log(this.feature)
        this.parseDate(this.data),
          await api
            .post("/Novidades/Add", {
              data: this.data,
              Descricao: this.descricao,
              Feature: this.feature
            })
            .then((result) => {
              this.$swal("Sucesso", result.data, "success");
              this.clearFields();
              this.$parent.resetload();
              this.$modal.hide("AddNovidade");
            })
            .catch((error) => {
              if (error.response.status == 400) {
                this.$swal(
                  "Erro",
                  "Não foi possível processar, verifique os campos.",
                  "error"
                );
              } else this.$swal("Erro", error.response.data, "error");
            });
      } else {
        this.$swal(
          "Erro",
          "Não foi possível processar, a data não pode ser posterior a data atual.",
          "error"
        );
      }
    },

    clearFields: function() {
      this.descricao = "";
    },

    parseDate: function(value) {
      value = moment(String(value)).format("YYYY-MM-DDT00:00:00-03:00");
    },
  },
  mounted() {},
  updated() {},
};
/* eslint-enable */
</script>
