<template>
    <div class="Grupos">
        <NavComponent id="NavComponent"></NavComponent>
        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>
        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="boxContent">
                        <div class="container text-left">
                            <br />
                            <h3 class="bold-title"><i class="fas fa-user-lock"></i> Acessos</h3>
                            <br />
                            <!-- eslint-disable -->
                            <div class="row">
                                <select class="form-control" v-on:change="resetload(); loadAcessos()" v-model="grupoSelecionado">
                                    <option v-for="grupo in listGrupos" :value="grupo.id">{{grupo.nome}}</option>
                                </select>
                            </div>
                            <br />
                            <div class="row" id="unseen">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Endereço</th>
                                            <th scope="col">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="acesso in acessosArr">
                                            <td>{{acesso.id}}</td>
                                            <td>{{acesso.endereco}}</td>
                                            <td>
                                                <a class="text-primary" href="#" v-on:click="editAcesso(acesso)"><i class="fas fa-edit"></i></a>
                                                <a class="text-danger" href="#" v-on:click="removeAcesso(acesso)"><i class="fas fa-trash"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <center>
                                <!-- <v-btn v-if="displaySeeMore"
                                       plain
                                       :loading="fetching"
                                       elevation="0"
                                       color="#888"
                                       width="300"
                                       @click="handleLoadMore"
                                       class="mt-6">
                                    Ver mais
                                </v-btn>
                                -->
                                <v-btn v-if="displaySeeMore"
                                       plain
                                       elevation="0"
                                       color="#888"
                                       width="300"
                                       @click="handleLoadMore"
                                       class="mt-6">
                                    Ver mais
                                </v-btn>
                            </center>
                            <!-- eslint-enable -->
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
            </div>
        </div>
        <AddAcesso :parent="this" />

        <EditAcesso :acesso="acessoEditando" :parent="this" />

        <a @click="addAcesso()" class="btnFloat">
            <FloatButton id="FloatButton" icon="fas fa-plus"></FloatButton>
        </a>
    </div>
</template>

<script>
    /* eslint-disable */
    import AddAcesso from './components/Acessos/AddAcesso.vue';
    import EditAcesso from './components/Acessos/EditAcesso.vue';

    import FloatButton from '../../../components/shared/FloatButton.vue';
    import NavComponent from '../../../components/shared/NavComponent.vue';
    import HeaderPadrao from '../../../components/shared/HeaderPadrao.vue';

    export default {
        name: 'Acessos',
        data: () => {
            return ({
                isAddingUsuario: false,
                grupoSelecionado: 1,
                listGrupos: "",
                acessos: "",
                acessoEditando: "",
                count: 0,
                results: 15,
                request: 0,
                listener: 1,
                acessosArr: [],
                user: null
            })
        },
        components: {
            NavComponent,
            HeaderPadrao,
            FloatButton,
            AddAcesso,
            EditAcesso
        },
        methods: {
            handleLoadMore: function () {
                this.listener = 1;
                this.request = 0;
                this.loadAcessos();
            },
            getUser: function () {
                var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                api.get("/Usuario/GetUserData?hash=" + cookie)
                    .then(response => {
                        this.user = response.data;
                    })
                    .catch(error => {
                        this.$swal("Oops!", error.response.data, "error");
                    })
            },
            fillSelects: function () {
                api.get("/Grupo/Get").then(result => {
                    this.listGrupos = result.data;
                });
            },
            resetload: function () {
                this.count = 0;
                this.acessosArr = [];
                //if (this.listener === 0)
                    //window.addEventListener('scroll', this.onScroll);
            },
            loadAcessos: function () {
                api.get("/Acesso/GetX?idGrupo=" + this.grupoSelecionado + "&count=" + this.count + "&results=" + this.results)
                .then(result => {
                    this.acessos = result.data;
                    if (this.acessos.length < this.results) {
                        this.listener = 0;
                        //window.removeEventListener('scroll', this.onScroll);
                    }
                    for (var x = 0; x < this.acessos.length; x++) {
                        this.acessosArr.push(this.acessos[x.toString()]);
                    }
                    this.count += this.results;
                    this.request = 1;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            addAcesso: function () {
                this.$modal.show('AddAcesso')//.then(response => { this.loadAcessos(); });
            },
            editAcesso: function (acesso) {
                this.acessoEditando= acesso;
                this.$modal.show('EditAcesso');
            },
            removeAcesso: function (acesso) {
                this.$swal({
                    title: "Confirme",
                    text: "Você tem certeza que deseja excluir o acesso " + acesso.endereco + "?",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Remover',
                    cancelButtonText: 'Cancelar'
                })
                .then(response => {
                    if (response.isConfirmed) {
                        api.delete("/Acesso/Remove?id=" + acesso.id + "&uid=" + this.user.id)
                        .then(result => {
                            this.$swal("Sucesso", result.data, "success");
                            var index = this.acessosArr.findIndex(e => e.id === acesso.id);
                            if (index > -1)
                                this.acessosArr.splice(index, 1);
                            this.loadAcessos();
                        })
                        .catch(error => {
                            this.$swal("Erro", error.data, "error");
                        });
                    }
                });
            },
            onScroll: function () {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight;
                if (bottomOfWindow && this.request) {
                    this.request = 0;
                    this.loadAcessos();
                }
            },
            getNext10: function () {
                // window.addEventListener('scroll', this.onScroll);
                this.listener = 1;
                // this.onScroll();
            }
        },
        beforeDestroy() {
            this.listener = 0;
            // window.removeEventListener('scroll', this.onScroll);
        },
        mounted: function () {
            this.fillSelects();
            this.loadAcessos();
            this.getNext10();
            var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            if (cookie) this.getUser();
        },
        computed: {
            displaySeeMore: function () {
                return this.listener;
            },
        }
    }
    /* eslint-enable */
</script>

