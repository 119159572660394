<template>
  <div class="DetalhesSolicitacao">
    <modal name="DetalhesSolicitacao" height="auto">
      <div class="container text-left p-3">
        <h4>Detalhes da solicitação</h4>
        <hr />
        {{ formatAtoLink }}
        <div class="mx-3">
          <b-row class="pb-0">
            <b-col class="col-md-6">
              <label for="numeroAto">
                Número
              </label>
              <input
                disabled
                class="form-control rule"
                type="text"
                id="numeroAto"
                :value="solicitacaoInspecionada.numeroAto"
              />
            </b-col>

            <b-col class="col-md-6">
              <label for="tipoAto">
                Tipo do ato:
              </label>
              <input
                disabled
                class="form-control rule"
                type="text"
                id="tipoAto"
                :value="solicitacaoInspecionada.tipoAto"
              />
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col class="col-md-6">
              <label for="email">
                Contato do solicitante
              </label>
              <input
                disabled
                class="form-control rule"
                type="text"
                id="email"
                :value="solicitacaoInspecionada.email"
              />
            </b-col>

            <b-col class="col-md-6">
              <label for="dataSolicitacao">
                Data da solicitação
              </label>
              <input
                disabled
                class="form-control rule"
                type="text"
                id="dataSolicitacao"
                :value="formatDate"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col class="py-1">
              <label class="m-0">
                <i class="fa fa-arrow-right" aria-hidden="true" />
                <a :href="formatAtoLink">Acesse o ato diretamente por aqui</a>
              </label>
            </b-col>
          </b-row>

          <b-row>
            <v-col class="pt-0 col-md-12">
              <label for="motivo">
                Motivo
              </label>
              <textarea
                disabled
                class="form-control rule"
                cols="28"
                rows="7"
                id="motivo"
                :value="solicitacaoInspecionada.motivo"
              />
            </v-col>
          </b-row>
          <b-row>
            <b-col>
              <button class="btn btn-info btn-block" @click="fecharDialog">
                Fechar
              </button>
            </b-col>
          </b-row>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  /* eslint-disable */
  name: "DetalhesSolicitacao",
  props: ["solicitacaoInspecionada", "solicitacaoInspecionada"],
  data: () => {
    return { formatedDate: "", atoLink: "", tipoAto: "none", numeroAto: "" };
  },
  components: {},
  computed: {
    formatAtoLink: function() {
      var numeroAto = this.$props.solicitacaoInspecionada.numeroAto;
      var tipoAto = this.$props.solicitacaoInspecionada.tipoAto
        ? this.$props.solicitacaoInspecionada.tipoAto
            .toLowerCase()
            .replace(/\s/g, "-")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        : "";

      //this.tipoAto = this.tipoAto.toLowerCase();

      if (tipoAto === "emenda" || tipoAto === "constituicao-estadual")
        tipoAto = "emenda-constitucional";

      return (this.atoLink = `https://legislacao.se.gov.br/visualizar/${tipoAto}/${numeroAto}`);
    },
    formatDate: function() {
      // moment(ficha.dataAto).format('YYYY-MM-DD')
      this.formatedDate = moment(
        this.solicitacaoInspecionada.dataSolicitacao
      ).format("DD-MM-YYYY");

      return this.formatedDate;
    },
  },
  methods: {
    fecharDialog: function() {
      this.$modal.hide("DetalhesSolicitacao");
    },
  },
};
</script>

<style>
#motivo {
  background-color: #fff;
}
</style>
