<template>
    <div id="app" class="container-fluid">
        <v-app>
            <Loading show="" />
            <router-view v-if="show" style=""></router-view>
        </v-app>
    </div>
</template>

<script>
/* eslint-disable */

import axios from 'axios';
import '@/assets/css/site.css';
import Loading from '@/components/shared/Loading.vue';


export default {
    name: 'app',
    data() {
        return {
            show: "",
           
        }
    },
    methods: {
        checkPermission: function () {
            let self = this;
            var exceptions = ["/SemPermissao", "/Login", "/NotFound"];
            LEGISON_TOKEN = self.$cookies.get('COOKIE_LEGISON_TOKEN');
            if (LEGISON_TOKEN != null) {
                if (!exceptions.includes(this.$route.path)) {
                    api.post("/Permissao",
                        {
                            Caminho: self.$route.path,
                            Hash: LEGISON_TOKEN
                        }
                    )
                        .then(response => {
                            if (response.data != true) self.$router.push("/SemPermissao");
                            // else self.$router.push("/Dashboard");
                        })
                        .catch(error => {
                            self.$router.push("/Login");
                        })
                        .then(response => {
                            this.configureAxios();
                        });
                }
            }
            else if (!exceptions.includes(this.$route.path)) self.$router.push("/Login");
        },
        
        configureAxios: function () {
            api = axios;
            api.defaults.baseURL = LEGISON_APIURL
            api.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
            api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
            api.defaults.headers.common['Authorization'] = this.$cookies.get('COOKIE_LEGISON_TOKEN');
        }
    },
    components: {
        Loading
    },
  
    mounted: function () {
        setTimeout(() => { this.show = true; }, 500)

        api = axios;
        api.defaults.baseURL = LEGISON_APIURL
        api.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
        api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        api.defaults.headers.common['Authorization'] = this.$cookies.get('COOKIE_LEGISON_TOKEN');

        let self = this;

        axios.interceptors.request.use((config) => {
            document.getElementById('overlay').className = "overlay show";
            document.getElementById('spanner').className = "LoadingFullscreen spanner show";
            return config;
        }, (error) => {
            document.getElementById('overlay').className = "overlay";
            document.getElementById('spanner').className = "LoadingFullscreen spanner";
            return Promise.reject(error);
        });

        axios.interceptors.response.use((response) => {
            document.getElementById('overlay').className = "overlay";
            document.getElementById('spanner').className = "LoadingFullscreen spanner";
            return response;
        }, (error) => {
            document.getElementById('overlay').className = "overlay";
            document.getElementById('spanner').className = "LoadingFullscreen spanner";
            return Promise.reject(error);
        });
    },
    updated: function () {
        this.checkPermission();
    },
    beforeCreate: () => {
        document.body.className = 'home';
    }
}
    /* eslint-enable */
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto');

#app {
    padding: 0;
    text-align: center;
    font-family: Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
    padding: 12px !important;
}

.btn-info {
    background-color: #066910 !important;
}
</style>
