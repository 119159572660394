<template>
    <div class="Usuarios">
        <NavComponent id="NavComponent"></NavComponent>

        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="boxContent">
                        <div class="container text-left">
                            <br />

                            <h3 class="bold-title"><i class="fas fa-users"></i> Usuários</h3>

                            <br />

                            <div class="row">
                                <input type="text" @keyup="searchTimeout()" v-model="pesquisa" class="form-control" placeholder="Pesquise pelo nome do usuário" />
                            </div>

                            <br />

                            <div class="row" id="unseen">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nome</th>
                                            <th scope="col">E-mail</th>
                                            <th scope="col">CPF</th>
                                            <th scope="col">Órgão</th>
                                            <th scope="col">Ações</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <!-- eslint-disable -->
                                        <tr v-for="usuario in usersArr">
                                            <td>{{ usuario.nome }}</td>
                                            <td>{{ usuario.email }}</td>
                                            <td>{{ usuario.cpf }}</td>
                                            <td>{{ usuario.orgao ? usuario.orgao.nome : orgNull(usuario) }}</td>
                                            <td>
                                                <a class="text-primary" href="#" @click="editUsuario(usuario)"><i class="fas fa-edit"></i></a>
                                                <a class="text-danger" href="#" @click="removeUsuario(usuario)"><i class="fas fa-trash"></i></a>
                                            </td>
                                        </tr>
                                        <!-- eslint-enable -->
                                    </tbody>
                                </table>
                            </div>
                            <center>
                                <!-- <v-btn v-if="displaySeeMore"
                                       plain
                                       :loading="fetching"
                                       elevation="0"
                                       color="#888"
                                       width="300"
                                       @click="handleLoadMore"
                                       class="mt-6">
                                    Ver mais
                                </v-btn>
                                -->
                                <v-btn v-if="displaySeeMore"
                                       plain
                                       elevation="0"
                                       color="#888"
                                       width="300"
                                       @click="handleLoadMore"
                                       class="mt-6">
                                    Ver mais
                                </v-btn>
                            </center>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
            </div>
        </div>
        <AddUsuario  :parent="this" />

        <EditUsuario :usuario="usuarioEditando" />

        <a @click="addUsuario()" class="btnFloat">
            <FloatButton id="FloatButton" icon="fas fa-plus"></FloatButton>
        </a>
    </div>
</template>

<script>
    /* eslint-disable */
    import AddUsuario from './components/Usuarios/AddUsuario.vue';
    import EditUsuario from './components/Usuarios/EditUsuario.vue';

    import FloatButton from '../../../components/shared/FloatButton.vue';
    import NavComponent from '../../../components/shared/NavComponent.vue';
    import HeaderPadrao from '../../../components/shared/HeaderPadrao.vue';

    export default {
        name: 'Usuarios',
        data: () => {
            return ({
                isAddingUsuario: false,
                usuarios: "",
                pesquisa: "",
                usuarioEditando: "",
                count: 0,
                results: 5,
                request: 0,
                listener: 1,
                usersArr: []
            })
        },
        components: {
            NavComponent,
            HeaderPadrao,
            FloatButton,
            AddUsuario,
            EditUsuario
        },
        computed: {
            displaySeeMore: function () {
                return this.listener;
            },
        },
        methods: {
            getUser: function () {
                var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                api.get("/Usuario/GetUserData?hash=" + cookie)
                    .then(response => {
                        this.user = response.data;
                    })
                    .catch(error => {
                        this.$swal("Oops!", error.response.data, "error");
                    });
            },
            resetload: function () {
                this.count = 0;
                this.usersArr = [];
                this.load();
            },
            searchTimeout: function () {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null
                }
                this.timeout = setTimeout(() => {
                    this.pesquisar();
                }, 800);
            },
            load: function () {
                api.get("/Usuario/GetX?pesquisa=" + this.pesquisa + "&count=" + this.count + "&results=" + this.results)
                .then(result => {
                    this.usuarios = result.data;
                    if (this.usuarios.length < this.results) this.listener = 0;
                    if (this.listener == 0 && this.usuarios.length >= this.results) this.listener = 1;
                    this.usersArr = this.usersArr.concat(this.usuarios);
                    this.count += this.results;
                    this.request = 1;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            pesquisar: function () {
                this.usuarios = "";
                this.count = 0;
                this.usersArr = [];
                this.load();
            },
            orgNull: function (usuario) {
                usuario.orgao = {nome: "Não Informado"};
                return usuario.orgao.nome;
            },
            addUsuario: function () {
                this.$modal.show('AddUsuario')//.then(response => { this.load(); });
            },
            editUsuario: function (usuario) {
                this.usuarioEditando = usuario;
                this.$modal.show('EditUsuario');
            },
            removeUsuario: function (usuario) {
                this.$swal({
                    title: "Confirme",
                    text: "Você tem certeza que deseja excluir o usuário " + usuario.nome + "?",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Remover',
                    cancelButtonText: 'Cancelar'
                })
                .then(response => {
                    if (response.isConfirmed) {
                        var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                        api.delete("/Usuario/Remove?id=" + usuario.id + "&hash=" + cookie)
                        .then(result => {
                            this.$swal("Sucesso", result.data, "success");
                            var index = this.usersArr.findIndex(e => e.id === usuario.id);
                            if (index > -1) this.usersArr.splice(index, 1);
                            this.load();
                        })
                        .catch(error => {
                            this.$swal("Erro", error.data, "error");
                        });
                    }
                });
            },
            handleLoadMore: function () {
                this.listener = 1;
                this.request = 0;
                this.load();
            },
            getResults: function () {
                this.listener = 1;
                this.load();
            }
        },
        mounted: function () {
            this.getResults();
        }
    }
    /* eslint-enable */
</script>

