<template>
    <nav id="about-nav" class="w-100">
        <div :class="$route.path === item.path ? 'selected' : ''" v-for="item in bar" :key="item.name"
            @click="$router.push(`${item.path}`); page = item.value" class="navItens">
            <span v-if="['md', 'lg', 'xl'].includes($vuetify.breakpoint.name)" v-text="item.name"></span>
            <v-icon left class="mx-2" v-text="item.icon"></v-icon>
        </div>
    </nav>
</template>


<script>
import { mdiBackburger, mdiChartTimelineVariant, mdiInformationOutline, mdiNewspaperVariantOutline } from '@mdi/js';
import { mdiMenu } from '@mdi/js';

export default {
    name: "NavComponent",
    data: () => {
        return {
            iconMenuClosed: mdiMenu,
            iconMenuOpened: mdiBackburger,
            bar: [
                { name: "Sobre", value: "sobre", path: "/Cadastros/Sobre", icon: mdiInformationOutline },
                { name: "Equipes", value: "team", path: "/Cadastros/Equipes", icon: mdiNewspaperVariantOutline },
                { name: "Linha do Tempo", value: "timeline", path: "/Cadastros/LinhaTempo", icon: mdiChartTimelineVariant }
            ],
        }
    },
    props: ['home'],
    methods: {
    }
}
</script>


<style>
@import './NavSaibaMais.css';
</style>
