<template>
    <div class="Home">
        <NavComponent id="NavComponent"></NavComponent>

        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="boxContent">
                        <div class="container text-left">
                            <br />
                            
                            <h3 class="bold-title"><i class="fas fa-cogs"></i> Configurações</h3>
                            
                            <hr />
                            
                            <br />
                            
                            <div class="row">
                                <div class="col-md-4">
                                    <button type="button" @click="$router.push('/Configuracoes/Acessos')" class="btn btn-primary btn-block btn-lg">
                                        <i class="fas fa-user-lock"> </i> <br />
                                        Acessos
                                    </button>
                                </div>

                                <div class="col-md-4">
                                    <button type="button" @click="$router.push('/Configuracoes/Grupos')" class="btn btn-primary btn-block btn-lg">
                                        <i class="fas fa-user-friends"></i> <br />
                                        Grupos
                                    </button>
                                </div>  

                                <div class="col-md-4">
                                    <button type="button" @click="$router.push('/Configuracoes/Usuarios')" class="btn btn-primary btn-block btn-lg">
                                        <i class="fas fa-users"></i> <br />
                                        Usuários
                                    </button>
                                </div>
                            </div>

                            <br />

                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center"> </div>
        </div>
    </div>
</template>

<script>
    import NavComponent from '../../components/shared/NavComponent.vue';
    import HeaderPadrao from '../../components/shared/HeaderPadrao.vue';

    export default {
        name: 'Home',
        components: {
            NavComponent,
            HeaderPadrao
        },
        data: () => {
            return ({
            })
        },
        methods: {
        },
        mounted: function () {
        },
    }
</script>

