<template>
    <div class="Dashboard">
        <NavComponent id="NavComponent"></NavComponent>

        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div id="boxContent">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-9 text-left">
                                    <br />

                                    <h3 class="bold-title">Oops!</h3>

                                    <h4>Não conseguimos encontrar o que você procurava.</h4>

                                    <hr />
                                    
                                    <p>Se você acredita que isso é um erro, e este recurso deveria existir, entre em contato com os administradores do sistema.</p>
                                </div>
                                
                                <div class="col-md-3">
                                    <img width="100%" src="../assets/imgs/naoencontrado.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
            </div>
        </div>

        <footer>
            <img src="../assets/imgs/logo-GovEstado-SE.png" alt="Sergipe, Governo do Estado" />
        </footer>
    </div>
</template>

<script>
    import NavComponent from '../components/shared/NavComponent.vue';
    import HeaderPadrao from '../components/shared/HeaderPadrao.vue';

    export default {
        name: 'Dashboard',
        data: () => {
            return ({
            })
        },
        components: {
            NavComponent,
            HeaderPadrao
        },
        methods: {
        },
        mounted: function () {
        }
    }
</script>

