<template>
  <main>
    <NavComponent id="NavComponent"></NavComponent>
    <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

    <div id="content" class="container container-about">
      <div id="info" class="d-flex flex-row justify-content-center">
        <div id="box" class="d-flex flex-row justify-content-start align-items-center">
          <div class="w-100">
            <div class="w-100 container-box text-left">
              <br />
              <h3 class="bold-title"><i class="fas fa-book-open"></i>Saiba Mais</h3>
              <br />
              <div name="AdicionarEquipe">
                <v-row>
                  <v-dialog v-model="dialog" max-width="600px">
                    <v-card>
                      <div class="card-title">
                        Adicionar uma Equipe
                      </div>
                      <hr id="hr-modal"/>
                      <v-card-text>
                        <v-container>
                          <v-row class="text-left" id="">
                            <v-col cols="12">
                              <div class="label-margin">Nome da Equipe</div>
                              <input 
                              placeholder="Escreva aqui o nome da Equipe" 
                              v-model="nomeEquipe" 
                              outlined
                              required
                              class="custom-border custom-input"
                            />
                            </v-col>
                          </v-row>
                        </v-container>
                        <small>*Indica um campo obrigatório</small>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="closeModal()">
                          Fechar
                        </v-btn>
                        <v-btn color="green darken-1" text @click="dialog = addEquipe()">
                          Enviar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </div>
              <div class="about-nav">
                <NavSaibaMais style="margin-bottom: 0rem !important;"></NavSaibaMais>
                <div id="ItemDisplay">
                  <a id="botao-adicionar" @click="dialog = true" href="#" class="btn btn-info botao-criar">
                    {{ "Adicionar Equipe" }}
                  </a>
                  <div v-for="equipe in equipes" :key="equipe.nomeEquipe">
                    <hr class="mt-4" />
                    <div class="mt-4">
                      <h2 class="d-flex align-items-center">
                        {{ equipe.nomeEquipe }}
                        <v-btn small icon @click="show !== equipe.nomeEquipe ? show = equipe.nomeEquipe : show = ''"
                          class="edit-icon">
                          <v-icon color="rgb(40, 167, 69)" v-text="show === equipe.nomeEquipe ? ic : icon" />
                        </v-btn>
                        <v-btn small icon @click="removerEquipe(equipe)">
                          <v-icon color="#DC3545" v-text="iconDelete"></v-icon>
                        </v-btn>
                      </h2>
                    </div>
                    <div v-if="show === equipe.nomeEquipe" class="mt-4 px-2">
                      <div class="texto">
                        <quill-editor ref="editorQuill" class="texto-editor" v-model="equipe.texto"
                          :options="editorOption" />
                      </div>
                      <a @click="salvar(equipe.id, equipe.nomeEquipe, equipe.texto)" href="#"
                        class="btn btn-info btn-block botao-salvar">{{ "Salvar" }}</a>
                    </div>
                  </div>
                </div>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
  </main>
</template>
    
<script>
import { mdiMinus, mdiPencil, mdiSortCalendarDescending, mdiDelete } from "@mdi/js";
import { mdiSortCalendarAscending } from "@mdi/js";
import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.snow.css'
import NavComponent from "../../../components/shared/NavComponent.vue";
import HeaderPadrao from "../../../components/shared/HeaderPadrao.vue";
import NavSaibaMais from "./components/NavSaibaMais.vue";


export default {
  name: "Equipe",
  components: {
    NavComponent,
    HeaderPadrao,
    NavSaibaMais,
    quillEditor
  },
  data: () => {
    return {
      icon: mdiPencil,
      ic: mdiMinus,
      iconDelete: mdiDelete,
      icons: {
        mdiSortAsc: mdiSortCalendarAscending,
        mdiSortDesc: mdiSortCalendarDescending,
      },
      loading: false,
      displaySeeMore: true,
      descricao: null,
      page: 1,
      order: "desc",
      user: null,
      novidadeEditando: "",
      totalItems: null,
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'header': 1}, { 'header': 2}],
            [{ 'size': ['small', false, 'large', 'huge']}],
            [{ 'header': [1, 2, 3, 4, 5, 6, false]}],
            [{ 'font': []}],
        ]
        }
      },
      equipes: [],
      content: "",
      show: '',
      dialog: false,
      nomeEquipe: "",
    };
  },
  methods: {
    getUser: function () {
      var cookie = this.$cookies.get("COOKIE_LEGISON_TOKEN");
      /* eslint-disable */
      api.get("/Usuario/GetUserData?hash=" + cookie)
        .then((response) => {
          this.user = response.data;
        })
        .catch((error) => {
          this.$swal("Oops!", error.response.data, "error");
        });
    },
    getEquipes: async function () {
      await api.get("/SaibaMais/GetAllEquipes")
        .then(result => {
          this.equipes = result.data;
        })
        .catch(error => {
          this.$swal("Erro", error.response.data, "error");
        });
    },
    salvar: async function (idEquipe, nomeEquipe, texto) {
      var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');

      const formData = new FormData();
      formData.append('IdEquipe', idEquipe);
      formData.append('Texto', texto);
      formData.append('NomeEquipe', nomeEquipe);

      await api.put("/SaibaMais/UpdateEquipe?hash=" + cookie, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(result => {
          this.$swal("Success", `${nomeEquipe} atualizada`, "success");
          
        })
        .catch(error => {
          this.$swal("Erro", error.response.data, "error");
        });
    },
    addEquipe: async function () {
      var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');

      const formData = new FormData();
      formData.append('NomeEquipe', this.nomeEquipe);
      formData.append('Texto', "");

      await api.post("/SaibaMais/AddEquipe?hash=" + cookie, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(result => {
        this.$swal("Success", `${this.nomeEquipe} adicionada`, "success");
        window.location.reload();
      })
      .catch(error => {
        this.$swal("Erro", error.response.data, "error");
      });
    },
    removerEquipe: async function (equipe) {
      this.$swal({
        title: "Confirme",
        text: "Você tem certeza que deseja excluir a equipe " + equipe.nomeEquipe,
        icon: "info",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Remover',
        cancelButtonText: 'Cancelar'
      })
        .then(async (response) => {
          if (response.isConfirmed) {
            var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            await api.delete("/SaibaMais/RemoveEquipe?hash=" + cookie + "&equipeId=" + equipe.id)
              .then(result => {
                this.$swal("Sucesso", result.data, "success");
                var index = this.equipes.findIndex(e => e.id == equipe.id);
                if (index > -1) this.equipes.splice(index, 1);
              })
              .catch(error => {
                this.$swal("Erro", error.response.data, "error");
              });
          }
        });
    },
    clearFields: function () {
      this.nomeEquipe = "";
    },
    closeModal: function () {
      this.clearFields();
      this.dialog = false;
    },
  },
  mounted: function () {
    this.getUser();
    this.getEquipes();
  }
}
</script>
    
<style>
@import './Equipe.css';
</style>
    