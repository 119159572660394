<template>
    <b-navbar toggleable="lg">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-navbar-brand class="ml-auto">
            <span class="d-inline-block d-lg-none">
                <router-link to="/">
                    <img src="../../assets/imgs/logoLegison.svg" alt="LegisOn" />
                </router-link>
            </span>
        </b-navbar-brand>

        <b-collapse class="container" id="nav-collapse" is-nav>
            <b-navbar-nav class="justify-content-between w-100">
                <b-nav-item active-class="active" class="nav-link" v-for="route in links" :key="route.id" :to="route.rota">
                    <i :class="route.icone"></i> {{route.nome}}
                </b-nav-item>
                <b-nav-item class="nav-link" key="999" v-on:click="logout()">
                    <i class="fas fa-sign-out-alt"></i> Sair
                </b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>


<script>
    /* eslint-disable */
    export default {
        name: "NavComponent",
        data: () => {
            return {
                links: [
                    {
                        id: 0,
                        nome: "Início",
                        rota: "/",
                        icone: "fas fa-home"
                    },
                    {
                        id: 1,
                        nome: "Cadastros",
                        rota: "/Cadastros",
                        icone: "fas fa-file-import"
                    },
                    {
                        id: 2,
                        nome: "Consultas",
                        rota: "/Consultas",
                        icone: "fas fa-search"
                    },
                    {
                        id: 3,
                        nome: "Configurações",
                        rota: "/Configuracoes",
                        icone: "fas fa-cogs"
                    },
                    {
                        id: 4,
                        nome: "Estatísticas",
                        rota: "/Estatisticas",
                        icone: "fas fa-chart-area"
                    }
                ]
            }
        },
        methods: {
            logout: function () {
                let self = this;
                var cookie = self.$cookies.get('COOKIE_LEGISON_TOKEN');
                api.post("/Logout?hash=" + cookie)
                    .then(response => {
                        self.$cookies.remove('COOKIE_LEGISON_TOKEN');
                        self.$router.push("/Login");
                    })
                    .catch(error => {})
            }

        }

    }
    /* eslint-enable */
</script>

<style>
    @import '../../assets/styles/components/NavComponent.css';
</style>