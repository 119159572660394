<template>
    <div class="btn-Prosseguir">
        <img src="../../assets/icons/arrow-next.svg" v-if="!loading" />
        <img src="../../assets/icons/loading.svg" class="loading" v-if="loading" />
    </div>
</template>

<script>
    export default {
        name: 'BtnProsseguir',
        props: ['loading'],
        data: () => {
            return ({
            })
        },
        components: {
        }
    }
</script>

