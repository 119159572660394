<template>
    <div class="Login">
        <div id="content" class="container">
            <HeaderDestaque id="HeaderDestaque"></HeaderDestaque>
            <br />
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="cardLogin" class="d-flex flex-row justify-content-start align-items-center">
                    <div id="cardLoginContent">

                        <form @keyup.enter="login()">
                            <div class="d-flex flex-row justify-content align-items-center">
                                <div class="p-1">
                                    <img src="../assets/icons/user.svg" class="icon-24" />
                                </div>

                                <div class="p-2">
                                    <label>Usuário</label>
                                </div>
                            </div>

                            <div class="input-row" style="border: 1px #999999 solid; border-radius: 10px;">
                                <input type="text" v-model="email" placeholder="" />
                            </div>

                            <div class="d-flex flex-row justify-content align-items-center">
                                <div class="p-1">
                                    <img src="../assets/icons/password.svg" class="icon-24" />
                                </div>

                                <div class="p-2">
                                    <label>Senha</label>
                                </div>
                            </div>

                            <div class="input-row" style="border: 1px #999999 solid; border-radius: 10px;">
                                <input type="password" v-model="senha" placeholder="" />
                            </div>
                        </form>
                        
                        <br />
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
                <a @keyup.enter="login()" @click="login()">
                    <BtnProsseguir :loading="isLoading" id="btnProsseguir" tabindex="0" > </BtnProsseguir>
                </a>
            </div>
        </div>

        <footer>
            <img src="../assets/imgs/logo-GovEstado-SE.png" alt="Sergipe, Governo do Estado" />
        </footer>
    </div>
</template>

<script>
    /* eslint-disable */
    // import swal from 'sweetalert';
    import HeaderDestaque from '../components/shared/HeaderDestaque.vue';
    import BtnProsseguir from '../components/shared/BtnProsseguir.vue';
    import '@/assets/css/site.css';

    export default {
        name: 'Login',
        data: () => {
            return ({
                isLoading: false,               
                email: "",
                senha: ""
            })
        },
        components: {
            HeaderDestaque,
            BtnProsseguir
        },
        methods: {
            login: function () {
                let self = this;
                self.isLoading = true;
                api.post("/Login", {
                    Email: this.email,
                    Senha: this.senha
                })
                .then(async response => {
                    
                    const FirstAcess = response.data.firstAcess
                    const IdUser = response.data.idUser
                    localStorage.setItem("IdUser", IdUser)
                    localStorage.setItem("FirstAcess", FirstAcess)
                    LEGISON_TOKEN = response.data.hash;
                    self.$cookies.set('COOKIE_LEGISON_TOKEN', response.data.hash);
                    api.defaults.headers.common['Authorization'] = response.data.hash;
                    //await new Promise (resolve => setTimeout(resolve, 5000));
                    self.$router.push("/Dashboard");
                })
                .catch(error => {
                    if (error.response != undefined) {
                        self.$cookies.remove('COOKIE_LEGISON_TOKEN');
                        this.$swal("Oops!", error.response.data, "error");
                    }
                    else console.log("Erro " + error);
                })
                .then(response => {
                    self.isLoading = false;
                });
            }
        },
        mounted: function () {
           
        }
    }
    /* eslint-enable */
</script>

