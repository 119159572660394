<template>
  <main>
    <NavComponent id="NavComponent"></NavComponent>
    <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

    <div id="content" class="container container-about">
      <div id="info" class="d-flex flex-row justify-content-center">
        <div id="box" class="d-flex flex-row justify-content-start align-items-center">
          <div class="w-100">
            <div class="w-100 container-box text-left">
              <br />
              <h3 class="bold-title"><i class="fas fa-book-open"></i>Saiba Mais</h3>
              <br />
              <div class="about-nav">
                <NavSaibaMais></NavSaibaMais>
              </div>
              <div class="texto">
                <quill-editor ref="editorQuill" class="texto-editor" v-model="content" :options="editorOption" />
              </div>
              <a @click="salvar()" href="#" class="btn btn-info btn-block botao-salvar">{{ "Salvar" }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
  </main>
</template>
  
<script>
import { mdiSortCalendarDescending } from "@mdi/js";
import { mdiSortCalendarAscending } from "@mdi/js";
import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.snow.css'
import NavComponent from "../../../components/shared/NavComponent.vue";
import HeaderPadrao from "../../../components/shared/HeaderPadrao.vue";
import NavSaibaMais from "./components/NavSaibaMais.vue";


export default {
  name: "Sobre",
  components: {
    NavComponent,
    HeaderPadrao,
    NavSaibaMais,
    quillEditor
  },
  data: () => {
    return {
      icons: {
        mdiSortAsc: mdiSortCalendarAscending,
        mdiSortDesc: mdiSortCalendarDescending,
      },
      loading: false,
      displaySeeMore: true,
      descricao: null,
      page: 1,
      order: "desc",
      user: null,
      novidadeEditando: "",
      totalItems: null,
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'header': 1}, { 'header': 2}],
            [{ 'list': 'bullet'}],
            [{ 'size': ['small', false, 'large', 'huge']}],
            [{ 'header': [1, 2, 3, 4, 5, 6, false]}],
            [{ 'font': []}],
            [{ 'align': []}]
        ]
        }
      },
      content: ""
    };
  },
  methods: {
    getUser: function () {
      var cookie = this.$cookies.get("COOKIE_LEGISON_TOKEN");
      /* eslint-disable */
      api.get("/Usuario/GetUserData?hash=" + cookie)
        .then((response) => {
          this.user = response.data;
        })
        .catch((error) => {
          this.$swal("Oops!", error.response.data, "error");
        });
    },
    getSobre: async function () {
      await api.get("/SaibaMais/GetSobreAtual")
        .then(result => {
          this.content = result.data.texto;
        })
        .catch(error => {
          this.$swal("Erro", error.response.data, "error");
        });
    },
    salvar: async function () {
      var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');

      const formData = new FormData();
      formData.append('Texto', this.content);

      await api.put("/SaibaMais/UpdateSobre?hash=" + cookie, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(result => {
          this.$swal("Success", `Texto sobre atualizado`, "success");
        })
        .catch(error => {
          this.$swal("Erro", error.response.data, "error");
        });
    },
  },
  mounted: function () {
    this.getUser();
    this.getSobre();
  }
}
</script>
  
<style>
@import './Sobre.css';
</style>
  